import React from 'react';
import PropTypes from "prop-types";
import {withStyles} from '@material-ui/core/styles';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import clsx from "clsx";

const styles = theme => ({
    root: {},
});

class NetworkErrorDialog extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        open: PropTypes.bool.isRequired,
        style: PropTypes.object,
        className: PropTypes.string,
    }

    handleReload() {
        window.location.reload();
    }

    render() {
        const {classes, style, className, open} = this.props;

        return (
            <Dialog
                open={open}
                style={style}
                className={clsx(classes.root, className)}
            >
                <DialogTitle>Netzwerkfehler</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Die angeforderten Daten konnten nicht geladen werden.<br/>
                        Bitte versuchen Sie es später erneut.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleReload} color="primary" autoFocus>
                        Neu laden
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(NetworkErrorDialog);
