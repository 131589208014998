import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {Paper, useMediaQuery} from "@material-ui/core";
import {Link, useLocation} from "react-router-dom";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        paddingLeft: 8,
        paddingRight: 8,
    },
    tabs: {},
}));

export default function Navbar(props) {
    const locations = props.locations;
    const classes = useStyles();
    const location = useLocation();
    const matchedLocation = locations.find(x =>
        x.exact ? location.pathname === x.location : location.pathname.startsWith(x.location));
    const value = matchedLocation ? matchedLocation.location : false;
    const smallScreen = useMediaQuery('(max-width:1024px)');

    return (
        <Paper elevation={3} className={classes.root}>
            <Tabs
                value={value}
                indicatorColor="primary"
                textColor="primary"
                variant={smallScreen ? "scrollable" : "standard" }
                centered={!smallScreen}
                scrollButtons="auto"
                className={classes.tabs}
            >
                {locations.map((item, index) => (
                    <Tab component={Link} to={item.location} value={item.location} label={item.label} key={index}/>
                ))}
            </Tabs>
        </Paper>
    );
}
