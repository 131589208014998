import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {Button, Grid, Paper} from "@material-ui/core";
import {Link} from "react-router-dom";

import {ROUTE_IMPRINT, ROUTE_PRIVACY} from "helpers/Constants";

import lswi from 'media/Logo_LSWI.jpg'
import tum from 'media/Logo_TUM_Lehrstuhl.jpg'
import aif from 'media/Logo_AIF.jpg'
import bmwi from 'media/Logo_BMWi.png'

const styles = theme => ({
    root: {},
    logo: {
        height: 46,
    }
});

class Footer extends React.Component {
    render() {
        const {classes} = this.props;
        return (
            <Paper elevation={3} className={classes.root}>
                <Grid container spacing={2} justify={"space-between"} alignItems="stretch">
                    <Grid container item xs={12} md={4} justify="space-evenly">
                        <Grid item>
                            <img src={lswi} className={classes.logo}
                                 alt="Lehrstuhl für Wirtschaftsinformatik, Prozesse und Systeme, Universität Potsdam"/>
                        </Grid>
                        <Grid item>
                            <img src={tum} className={classes.logo}
                                 alt="Lehrstuhl für Strategie und Organisation, Technische Universität München"/>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} md={4} alignItems="center" justify="center" direction="column">
                        <Grid item>
                            <Button component={Link} to={ROUTE_IMPRINT}>Impressum</Button>
                        </Grid>
                        <Grid item>
                            <Button component={Link} to={ROUTE_PRIVACY}>Datenschutzerklärung</Button>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} md={4} justify="space-evenly">
                        <Grid item>
                            <img src={aif} alt="Arbeitsgemeinschaft industrieller Forschungsvereinigungen"/>
                        </Grid>
                        <Grid item>
                            <img src={bmwi} alt="Gefördert durch das Bundesministerium für Wirtschaft und Energie"/>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        );
    }
}

Footer.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Footer);
