import React from 'react';
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";

import LoginDialog from "components/LoginDialog";
import {
    ROUTE_ADMIN_BEST_PRACTICES,
    ROUTE_ADMIN_BEST_PRACTICES_EDIT,
    ROUTE_ADMIN_CROWDS,
    ROUTE_ADMIN_CROWDS_EDIT,
    ROUTE_ADMIN_FILES,
    ROUTE_ADMIN_MASTER_DATA,
} from "helpers/Constants";
import BaseLayout from "layouts/BaseLayout";
import AdminMasterData from "pages/AdminMasterData";
import AdminCrowds from "pages/AdminCrowds";
import AdminBestPractices from "pages/AdminBestPractices";
import AdminFiles from "pages/AdminFiles";
import {getUser} from "helpers/api";
import AdminBestPracticesEdit from "pages/AdminBestPracticesEdit";
import AdminCrowdsEdit from "pages/AdminCrowdsEdit";
import {eventBus} from "helpers/EventBus";

const styles = theme => ({
    root: {},
});

const navigation = [
    {location: ROUTE_ADMIN_MASTER_DATA, label: 'Stammdaten', exact: true},
    {location: ROUTE_ADMIN_CROWDS, label: 'Crowds', exact: false},
    {location: ROUTE_ADMIN_BEST_PRACTICES, label: 'Praxisbeispiele', exact: false},
    {location: ROUTE_ADMIN_FILES, label: 'Dateien', exact: true},
];

const routes = [
    {path: ROUTE_ADMIN_MASTER_DATA, component: AdminMasterData},
    {path: ROUTE_ADMIN_CROWDS, component: AdminCrowds},
    {path: ROUTE_ADMIN_CROWDS_EDIT, component: AdminCrowdsEdit},
    {path: ROUTE_ADMIN_CROWDS_EDIT + "/:id", component: AdminCrowdsEdit},
    {path: ROUTE_ADMIN_BEST_PRACTICES, component: AdminBestPractices},
    {path: ROUTE_ADMIN_BEST_PRACTICES_EDIT, component: AdminBestPracticesEdit},
    {path: ROUTE_ADMIN_BEST_PRACTICES_EDIT + "/:id", component: AdminBestPracticesEdit},
    {path: ROUTE_ADMIN_FILES, component: AdminFiles},
];

class AdminMain extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
    }

    constructor(props) {
        super(props);
        this.state = {
            isAuthenticated: false,
            username: "",
        };

        this.handleLogin = this.handleLogin.bind(this);
        this.handleUnauthorized = this.handleUnauthorized.bind(this);
    }

    componentDidMount() {
        eventBus.on('HttpErrorUnauthorized', this.handleUnauthorized);

        // check API authentication status
        getUser()
            .then((user) => {
                this.handleLogin(user);
            })
            .catch(() => {
                this.setState({
                    isAuthenticated: false,
                    username: ""
                });
            });
    }

    componentWillUnmount() {
        eventBus.remove('HttpErrorUnauthorized', this.handleUnauthorized);
    }

    handleLogin(user) {
        this.setState({
            isAuthenticated: true,
            username: user.name
        })
    }

    handleUnauthorized() {
        this.setState({
            isAuthenticated: false,
        });
    }

    render() {
        const {classes} = this.props;
        const {isAuthenticated} = this.state;

        return (
            <React.Fragment>
                <LoginDialog open={!isAuthenticated} handleLogin={this.handleLogin}/>
                {isAuthenticated && <BaseLayout navigation={navigation} routes={routes} className={classes.root}/>}
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(AdminMain);
