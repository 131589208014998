import React from 'react';
import PropTypes from "prop-types";
import {withStyles} from '@material-ui/core/styles';
import {Grid, Button, Typography,} from '@material-ui/core';
import {ArrowForward, ArrowForwardIos} from "@material-ui/icons";
import clsx from "clsx";
import {Link} from "react-router-dom";

import StageChoicer from "components/StageChoicer";
import SectionHeading from "components/SectionHeading";
import CrowdTable from "components/CrowdTable";
import {getCrowds} from "helpers/api";
import {CROWDSOURCING_ACTIVITIES, CROWDSOURCING_STAGES, ROUTE_BEST_PRACTICE} from "helpers/Constants";

import imgAskQuestion from "media/Icons/askQuestion.png";
import imgCentralizedNetwork from "media/Icons/centralizedNetwork.png";
import imgCrowd from "media/Icons/crowd.png";
import imgLockOrientation from "media/Icons/lockOrientation.png";
import imgPrize from "media/Icons/prize.png";
import imgStopwatch from "media/Icons/stopwatch.png";
import FullscreenProgress from "components/FullscreenProgress";
import NetworkErrorDialog from "components/NetworkErrorDialog";

const styles = theme => ({
    stageInfoTitle: {
        textAlign: 'center'
    },
    body1: {
        ...theme.typography.body1
    },
    paragraph: {
        ...theme.typography.paragraph
    },
    activityButton: {
        height: '100%'
    },
    activityButtonContainer: {
        alignSelf: 'stretch',
    },
    hintImage: {
        display: 'block',
        width: '50%'
    },
    hintText: {
        textAlign: 'center',
        padding: theme.spacing(2, 1, 2),
    },
    hintContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
});

class APCstart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            crowds: null,
            isLoading: true,
            networkError: false,
            stage: -1,
            activity: -1
        };

        this.handleStageChange = this.handleStageChange.bind(this);
        this.handleActivityButton = this.handleActivityButton.bind(this);
    }

    componentDidMount() {
        getCrowds()
            .then((result) => {
                this.setState({
                    isLoading: false,
                    crowds: result
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    isLoading: false,
                    networkError: true,
                });
            });
    }

    handleActivityButton(activity) {
        this.setState({
            activity: this.state.activity !== activity ? activity : -1,
        });
    }

    handleStageChange(newStage) {
        this.setState({
            stage: newStage,
            activity: -1
        });
    }

    render() {
        const {classes} = this.props;
        const {stage, activity, isLoading, networkError} = this.state;

        let stageInfo = {};
        if (stage >= 0) {
            stageInfo = CROWDSOURCING_STAGES.find(x => x.id === stage);
        }

        let activityInfo = {};
        let crowds = [];
        if (activity >= 0) {
            activityInfo = CROWDSOURCING_ACTIVITIES.find(x => x.id === activity);
            for (const crowd of this.state.crowds) {
                const activity = crowd.activities.find(a => a.activity.id === activityInfo.id);
                if(activity) {
                    let languages = [];
                    let language_codes = [];
                    let task_forms = [];
                    for(const lang of crowd.languages) {
                        languages.push(lang.language);
                        language_codes.push(lang.shorthand);
                    }
                    let expertises = [];
                    for(const expertise of activity.expertises) {
                        expertises.push(expertise.description);
                    }
                    for(const tf of crowd.task_forms) task_forms.push(tf.description);
                    crowds.push({
                        id: crowd.id,
                        name: crowd.name,
                        website: crowd.website,
                        expertises: expertises,
                        languages: languages,
                        language_codes: language_codes,
                        task_forms: task_forms,
                        participants: crowd.participants.description,
                    })
                }
            }
        }

        const propsHintContainer = {xs: 6, sm: 4, md: 2, className: classes.hintContainer};

        return (
            <React.Fragment>
                <FullscreenProgress open={isLoading}/>
                <NetworkErrorDialog open={networkError}/>

                <SectionHeading><b>Wählen Sie Ihre Stufe</b> im Produktentwicklungsprozess:</SectionHeading>

                <StageChoicer stage={stage} onChange={this.handleStageChange}/>

                {stage >= 0 && (<React.Fragment>
                    <SectionHeading>{stageInfo.description}</SectionHeading>
                    <Typography>
                        Das steckt hinter dieser Stufe:
                    </Typography>
                    <ul className={clsx(classes.body1, classes.paragraph)}>
                        {stageInfo.backgrounds.map((item, idx) => (
                            <li key={idx}>{item}</li>
                        ))}
                    </ul>
                    <Typography paragraph>
                        <ArrowForward/> {stageInfo.benefit}
                    </Typography>
                    <Typography paragraph>
                        Sie wissen nicht, ob Sie sich auf der richtigen Stufe befinden?<br/>
                        Werfen Sie doch einen Blick in unsere Praxisbeispiele.
                    </Typography>
                    <Typography paragraph>
                        <Button component={Link} to={{pathname: ROUTE_BEST_PRACTICE, state: {stage: stage}}}
                                variant="outlined" color="primary" startIcon={<ArrowForwardIos/>}>
                            Erfahren Sie mehr
                        </Button>
                    </Typography>

                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={6}>
                            <SectionHeading>Ihre Möglichkeiten</SectionHeading>
                            <Typography align="justify">{stageInfo.opportunities}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <SectionHeading>Ihre Herausforderungen</SectionHeading>
                            <Typography align="justify">{stageInfo.challenges}</Typography>
                        </Grid>
                    </Grid>

                    <SectionHeading>Sie fragen sich, welcher Crowdanbieter der Richtige ist?</SectionHeading>
                    <Typography paragraph>
                        Es wird Ihnen helfen eine dieser Fragen zu beantworten:
                    </Typography>

                    <Grid container spacing={4} alignItems="center">
                        {CROWDSOURCING_ACTIVITIES.filter(x => x.stage.id === stage).map((item, idx) => (
                            <Grid item xs={12} sm={6} key={idx} className={classes.activityButtonContainer}>
                                <Button color="primary" onClick={() => this.handleActivityButton(item.id)} fullWidth
                                        variant={activity === item.id ? "contained" : "outlined"}
                                        className={classes.activityButton}>
                                    {(idx + 1).toString() + ". " + item.question}
                                </Button>
                            </Grid>
                        ))}
                    </Grid>
                </React.Fragment>)}

                {activity >= 0 && (<React.Fragment>
                    <SectionHeading>Hinweise zur Erstellung der Aufgabe</SectionHeading>
                    <Grid container spacing={1}>
                        <Grid item {...propsHintContainer}>
                            <img src={imgCentralizedNetwork} alt="Komplexität" className={classes.hintImage}/>
                            <Typography className={classes.hintText}>{activityInfo.hints.complexity}</Typography>
                        </Grid>
                        <Grid item  {...propsHintContainer}>
                            <img src={imgAskQuestion} alt="Aufgabenstellung" className={classes.hintImage}/>
                            <Typography className={classes.hintText}>{activityInfo.hints.taskProperties}</Typography>
                        </Grid>
                        <Grid item {...propsHintContainer}>
                            <img src={imgCrowd} alt="Aufgabenform" className={classes.hintImage}/>
                            <Typography className={classes.hintText}>{activityInfo.hints.taskForm}</Typography>
                        </Grid>
                        <Grid item {...propsHintContainer}>
                            <img src={imgPrize} alt="Kompensation" className={classes.hintImage}/>
                            <Typography className={classes.hintText}>{activityInfo.hints.compensation}</Typography>
                        </Grid>
                        <Grid item {...propsHintContainer}>
                            <img src={imgStopwatch} alt="Bearbeitungszeit" className={classes.hintImage}/>
                            <Typography className={classes.hintText}>{activityInfo.hints.processingTime}</Typography>
                        </Grid>
                        <Grid item {...propsHintContainer}>
                            <img src={imgLockOrientation} alt="Geheimhaltung" className={classes.hintImage}/>
                            <Typography className={classes.hintText}>{activityInfo.hints.confidentiality}</Typography>
                        </Grid>
                    </Grid>

                    <CrowdTable rows={crowds}/>
                </React.Fragment>)}
            </React.Fragment>
        );
    }
}

APCstart.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(APCstart);
