import React from 'react';
import PropTypes from "prop-types";
import {withStyles} from '@material-ui/core/styles';
import {ButtonBase, Grid, Paper, Typography} from "@material-ui/core";
import clsx from "clsx";
import CompanyLogo from "components/CompanyLogo";

const styles = theme => ({
    root: {
        width: '100%',
    },
    gridContainerTitle: {
        alignItems: 'center',
    },
    gridContainerLogos: {
        height: 150,
        alignItems: 'center',
    },
    titleText: {
        fontWeight: 'bold',
    },
    titleContainer: {
        height: '5rem',
    },
    paper: {
        width: '100%',
        padding: theme.spacing(1, 2, 1),
    }
});

class BestPracticeCardSmall extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);
        this.state = {};
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        if(this.props.onClick) {
            this.props.onClick(this.props.data.id);
        }
    }

    render() {
        const {classes, className, style} = this.props;
        const {title, crowd_logo, client_logo, client_name} = this.props.data;
        const crowd_name = this.props.data.crowd.name;

        return (
            <ButtonBase style={style}
                        className={clsx(classes.root, className)}
                        onClick={this.handleClick}
            >
                <Paper square variant="outlined" className={classes.paper}>
                    <Grid container spacing={1} className={classes.gridContainerTitle}>
                        <Grid item xs={12} className={classes.titleContainer}>
                            <Typography align="center" className={classes.titleText}>{title}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} className={classes.gridContainerLogos}>
                        <Grid item xs={6}>
                            <CompanyLogo name={crowd_name} logo={crowd_logo}/>
                        </Grid>
                        <Grid item xs={6}>
                            <CompanyLogo name={client_name} logo={client_logo}/>
                        </Grid>
                    </Grid>
                </Paper>
            </ButtonBase>
        );
    }
}

export default withStyles(styles)(BestPracticeCardSmall);
