import React from 'react';
import PropTypes from "prop-types";
import {withStyles} from '@material-ui/core/styles';
import {Typography} from "@material-ui/core";
import clsx from "clsx";
import {fileURL} from "helpers/api";

const styles = theme => ({
    root: {
        textAlign: 'center',
    },
    name: {
        color: theme.palette.text.secondary,
    },
    logo: {
        display: 'block',
        width: '100%'
    }
});

class CompanyLogo extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        name: PropTypes.string.isRequired,
        logo: PropTypes.string,
    }

    render() {
        const {classes, style, className, name, logo} = this.props;

        return (
            <div style={style} className={clsx(classes.root, className)}>
                {logo ? (
                    <img src={fileURL(logo)} alt={name} className={classes.logo}/>
                ) : (
                    <Typography className={classes.name}>
                        {name}
                    </Typography>
                )}
            </div>
        );
    }
}

export default withStyles(styles)(CompanyLogo);
