import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';

import {
    ROUTE_BEST_PRACTICE,
    ROUTE_CONTACT,
    ROUTE_CROWD_PROVIDERS,
    ROUTE_CROWD_USERS,
    ROUTE_HELP,
    ROUTE_IMPRINT,
    ROUTE_PRIVACY,
    ROUTE_START
} from "helpers/Constants";
import Start from "pages/Start"
import APCstart from "pages/APCstart";
import CrowdAnbieter from "pages/CrowdAnbieter";
import Impressum from "pages/Impressum";
import Datenschutz from "pages/Datenschutz";
import Hilfe from "pages/Hilfe";
import BestPractice from "pages/BestPractice";
import Contact from "pages/Contact";
import BaseLayout from "layouts/BaseLayout";

const styles = theme => ({
    root: {},
});

const navigation = [
    {location: ROUTE_START, label: 'Start', exact: true},
    {location: ROUTE_CROWD_USERS, label: 'Crowd finden', exact: true},
    {location: ROUTE_CROWD_PROVIDERS, label: 'Crowd anbieten', exact: true},
    {location: ROUTE_BEST_PRACTICE, label: 'Praxis-Beispiele', exact: true},
    {location: ROUTE_HELP, label: 'Hilfe', exact: true},
    {location: ROUTE_CONTACT, label: 'Kontakt', exact: true},
];

const routes = [
    {path: ROUTE_START, component: Start},
    {path: ROUTE_CROWD_USERS, component: APCstart},
    {path: ROUTE_CROWD_PROVIDERS, component: CrowdAnbieter},
    {path: ROUTE_BEST_PRACTICE, component: BestPractice},
    {path: ROUTE_HELP, component: Hilfe},
    {path: ROUTE_CONTACT, component: Contact},
    {path: ROUTE_IMPRINT, component: Impressum},
    {path: ROUTE_PRIVACY, component: Datenschutz},
];

class Main extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
    }

    render() {
        const {classes} = this.props;
        return <BaseLayout navigation={navigation} routes={routes} className={classes.root}/>;
    }
}

export default withStyles(styles)(Main);
