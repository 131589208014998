import React from 'react';
import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {Button, Container, Grid} from "@material-ui/core";

import SectionHeading from "components/SectionHeading";
import ContactForm from "components/ContactForm";

const styles = theme => ({
    root: {},
    centerText: {
        textAlign: 'center',
    },
    selectorButtonGrid: {
        marginTop: '15px',
        marginBottom: '15px',
    },
    selectorButton: {
        hyphens: 'none',
    }
});

class Contact extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);
        this.state = {
            formVariant: null
        }
        this.handleSelectorButton = this.handleSelectorButton.bind(this);
    }

    componentDidMount() {
        // get form configuration options from router
        if (typeof this.props.location.state !== 'undefined') {
            if (typeof this.props.location.state.feedbackOption !== 'undefined') {
                this.setState({formVariant: this.props.location.state.feedbackOption})
            }
        }
    }

    handleSelectorButton(formVariant) {
        let newFormVariant = formVariant !== this.state.formVariant ? formVariant : null;
        this.setState({formVariant: newFormVariant});
    }

    render() {
        const {classes} = this.props;
        const {formVariant} = this.state;

        return (
            <React.Fragment>
                <SectionHeading>Kontaktformular</SectionHeading>

                <Container>
                    <Grid container justify="space-evenly" spacing={3} className={classes.selectorButtonGrid}>
                        <Grid item xs={12} md={4}>
                            <Button variant={formVariant === "newcrowd" ? "contained" : "outlined"} color="primary"
                                    fullWidth onClick={() => this.handleSelectorButton("newcrowd")}
                                    className={classes.selectorButton}>
                                Einen neuen Anbieter übermitteln
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Button variant={formVariant === "changecrowd" ? "contained" : "outlined"} color="primary"
                                    fullWidth onClick={() => this.handleSelectorButton("changecrowd")}
                                    className={classes.selectorButton}>
                                Eine Änderung/Fehler melden
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Button variant={formVariant === "contact" ? "contained" : "outlined"} color="primary"
                                    fullWidth onClick={() => this.handleSelectorButton("contact")}
                                    className={classes.selectorButton}>
                                Kontakt aufnehmen
                            </Button>
                        </Grid>
                    </Grid>
                </Container>

                <ContactForm variant={formVariant}/>
            </React.Fragment>
        );
    }

}

export default withRouter(withStyles(styles)(Contact));
