import imgDefinition from "media/StageChoicer/definition.png";
import imgDefinitionDisabled from "media/StageChoicer/definitionDisabled.png";
import imgEntdeckung from "media/StageChoicer/entdeckung.png";
import imgEntdeckungDisabled from "media/StageChoicer/entdeckungDisabled.png";
import imgEntwicklung from "media/StageChoicer/entwicklung.png";
import imgEntwicklungDisabled from "media/StageChoicer/entwicklungDisabled.png";
import imgKonkretisierung from "media/StageChoicer/konkretisierung.png";
import imgKonkretisierungDisabled from "media/StageChoicer/konkretisierungDisabled.png";
import imgMarkteinfuehrung from "media/StageChoicer/markteinfuehrung.png";
import imgMarkteinfuehrungDisabled from "media/StageChoicer/markteinfuehrungDisabled.png";
import imgTest from "media/StageChoicer/test.png";
import imgTestDisabled from "media/StageChoicer/testDisabled.png";

export const ROUTE_START = "/";
export const ROUTE_CROWD_USERS = "/crowd-finden";
export const ROUTE_CROWD_PROVIDERS = "/crowd-anbieten";
export const ROUTE_BEST_PRACTICE = "/praxis-beispiele";
export const ROUTE_HELP = "/hilfe";
export const ROUTE_CONTACT = "/kontakt";
export const ROUTE_IMPRINT = "/impressum";
export const ROUTE_PRIVACY = "/datenschutz";
export const ROUTE_ADMIN_MASTER_DATA = "/admin";
export const ROUTE_ADMIN_FILES = "/admin/files";
export const ROUTE_ADMIN_CROWDS = "/admin/crowds";
export const ROUTE_ADMIN_CROWDS_EDIT = ROUTE_ADMIN_CROWDS + "/edit";
export const ROUTE_ADMIN_BEST_PRACTICES = "/admin/best-practices";
export const ROUTE_ADMIN_BEST_PRACTICES_EDIT = ROUTE_ADMIN_BEST_PRACTICES + "/edit";

export const CROWDSOURCING_STAGES = [
    {
        id: 1,
        icon: imgEntdeckung,
        iconDisabled: imgEntdeckungDisabled,
        description: "Entdeckung",
        backgrounds: [
            "Identifizierung von Bedürfnissen",
            "Generierung von Ideen"
        ],
        benefit: "Nutzen Sie die Crowd, um die richtigen Projekte für eine erfolgreiche Zukunft auszuwählen.",
        opportunities:
            "Nutzen Sie die Vorteile der Crowd als Ideengenerator.\n" +
            "Unabhängige Sicht und Vielfalt der Crowd als Möglichkeit für disruptive Ideen.",
        challenges:
            "Bedürfnisse Ihrer Kunden zu identifizieren und entsprechende Ideen dafür zu finden ist nicht leicht.",
    },
    {
        id: 2,
        icon: imgKonkretisierung,
        iconDisabled: imgKonkretisierungDisabled,
        description: "Konkretisierung",
        backgrounds: [
            "Konkretisierung der Anwendungsbereiche",
            "Durchführung von Markt- und Wettbewerbsanalysen",
        ],
        benefit:
            "Nutzen Sie die Crowd, um die richtigen Projekte auszuwählen, deren erfolgreiche Implementierung " +
            "machbar erscheint.",
        opportunities:
            "Nutzen Sie die Vorteile der Crowd als Voter oder Rater.\n" +
            "Menge und Vielfalt der Crowd als Möglichkeit für eine große Anzahl an Rückmeldungen.",
        challenges: "Gerade Due Diligence Prozesse können sehr herausfordernd und zeitintensiv sein."
    },
    {
        id: 3,
        icon: imgDefinition,
        iconDisabled: imgDefinitionDisabled,
        description: "Definition",
        backgrounds: [
            "Definition des Business Case",
            "Finanz- und Profitabilitätsanalysen",
            "Test der Produktidee / Konzept mit potentiellen Kunden",
        ],
        benefit: "Nutzen Sie die Crowd, um die richtigen Projekte für eine erfolgreiche Zukunft auszuwählen.",
        opportunities:
            "Nutzen Sie die Vorteile der Crowd als Conceptualizer.\n" +
            "Qualifikationen der Crowd als Möglichkeit für qualifizierte Vorschläge.",
        challenges:
            "Die richtigen Produkttester in Ihrer spezifischen Zielgruppe zu finden kann unter " +
            "Umständen zeitintensiv und schwierig sein."
    },
    {
        id: 4,
        icon: imgEntwicklung,
        iconDisabled: imgEntwicklungDisabled,
        description: "Entwicklung",
        backgrounds: [
            "Festlegung des Designs und der Technik des Produktes oder der Dienstleistung",
            "Entwicklung von Prototypen entsprechend dem definierten Projektplan und den Anforderungen",
        ],
        benefit:
            "Nutzen Sie die Crowd, um einen Prototyp zu entwickeln, der in der nächsten Phase potentiellen Kunden " +
            "vorgestellt werden kann.",
        opportunities:
            "Nutzen Sie die Vorteile der Crowd als Designer.\n" +
            "Qualifikation der Crowd als Möglichkeit für eine höhere Nutzbarkeit des Endproduktes.\n" +
            "Kostengünstige Erstellung des Prototypen.",
        challenges:
            "Je höher die Komplexität, desto höher die Kompensation um Experten mit notwendiger Ausbildung zu gewinnen."
    },
    {
        id: 5,
        icon: imgTest,
        iconDisabled: imgTestDisabled,
        description: "Test",
        backgrounds: [
            "Test des Prototypen hinsichtlich Qualität und Nutzen für den Kunden",
        ],
        benefit:
            "Nutzen Sie die Crowd, um diverses Feedback einzuholen und dieses an die Vorstellung der potentiellen " +
            "Kunden anzupassen.",
        opportunities:
            "Nutzen Sie die Vorteile der Crowd als Produkttester\n" +
            "Qualifikation und Vielfalt der Crowd als Möglichkeit für Feedback zum getesteten Produkt.",
        challenges:
            "Durch die intensive Beschäftigung mit diesem Produkt fehlt Ihnen eine differenzierte Sicht, wodurch " +
            "die Identifizierung von Fehlern eine Herausforderung darstellt."
    },
    {
        id: 6,
        icon: imgMarkteinfuehrung,
        iconDisabled: imgMarkteinfuehrungDisabled,
        description: "Markteinführung",
        backgrounds: [
            "Markteinführung des Produkts / der Dienstleistung",
        ],
        benefit:
            "Nutzen Sie die Crowd, um die Lösung bestmöglich zu bewerben und einen guten Produkteinführung zu " +
            "ermöglichen.",
        opportunities:
            "Nutzen Sie die Vorteile der Crowd als Vermarkter.\n" +
            "Vielfalt der Crowd als Möglichkeit für eine glaubwürdige Vermarktung.\n" +
            "Einsparung von Marketingkosten",
        challenges:
            "Fehlende Sprachkenntnisse, Online Marketing Fähigkeiten und UX-Kenntnisse stellen potentielle" +
            "Schwierigkeiten bei einer Produkteinführung in einen neuen Markt dar."
    },
];

export const CROWDSOURCING_ACTIVITIES = [
    {
        id: 1,
        stage: CROWDSOURCING_STAGES[0],
        question: "Welche Bedürfnisse/Probleme haben (potenzielle) Kunden?",
        hints: {
            complexity: "Geringe Komplexität",
            taskProperties: "Offene, unbegrenzte & anregende Aufgabenstellung",
            taskForm: "Kontest oder Complementor (Aufgabenform)",
            compensation: "Intrinsische Kompensation",
            processingTime: "Ausreichend Zeit zur Bearbeitung",
            confidentiality: "Öffentliche Aufgabenübermittlung"
        }
    },
    {
        id: 2,
        stage: CROWDSOURCING_STAGES[0],
        question:
            "Welche möglichen Lösungen könnten die Bedürfnisse adressieren? Welche Lösungen gibt es für " +
            "spezifische Bedürfnisse?",
        hints: {
            complexity: "Geringe Komplexität",
            taskProperties: "Offene, unbegrenzte & anregende Aufgabenstellung",
            taskForm: "Kontest oder Complementor (Aufgabenform)",
            compensation: "Intrinsische Kompensation",
            processingTime: "Ausreichend Zeit zur Bearbeitung",
            confidentiality: "Öffentliche Aufgabenübermittlung"
        }
    },
    {
        id: 3,
        stage: CROWDSOURCING_STAGES[1],
        question: "Was wäre ein potenzieller Markt für eine mögliche Lösung? Wer wären Wettbewerber?",
        hints: {
            complexity: "Geringe Komplexität",
            taskProperties: "Prohibitive Aufgabenstellung",
            taskForm: "Kontest oder Complementor (Aufgabenform)",
            compensation: "Intrinsische Kompensation",
            processingTime: "Ausreichend Zeit zur Bearbeitung",
            confidentiality: "Öffentliche Aufgabenübermittlung"
        }
    },
    {
        id: 4,
        stage: CROWDSOURCING_STAGES[1],
        question: "Ist die Umsetzung der möglichen Lösung technisch machbar?",
        hints: {
            complexity: "Geringe Komplexität",
            taskProperties: "Prohibitive Aufgabenstellung",
            taskForm: "Kontest oder Complementor (Aufgabenform)",
            compensation: "Intrinsische und / oder extrinsische Kompensation",
            processingTime: "Ausreichend Zeit zur Bearbeitung",
            confidentiality: "Öffentliche Aufgabenübermittlung"
        }
    },
    {
        id: 5,
        stage: CROWDSOURCING_STAGES[2],
        question: "Ist eine Umsetzung der Lösung finanziell möglich und profitabel?",
        hints: {
            complexity: "Hohe Komplexität",
            taskProperties: "Konkrete & ausführliche Aufgabenstellung",
            taskForm: "Labor Market o. Collaborative Community (Aufgabenform)",
            compensation: "Extrinsische Kompensation",
            processingTime: "Kurze Zeit zur Bearbeitung",
            confidentiality: "Private Aufgabenübermittlung"
        }
    },
    {
        id: 6,
        stage: CROWDSOURCING_STAGES[2],
        question: "Sind potenzielle Kunden mit der Konzeptionierung der möglichen Lösung zufrieden?",
        hints: {
            complexity: "Geringe oder hohe Komplexität",
            taskProperties: "Konkrete & ausführliche Aufgabenstellung",
            taskForm: "Labor Market o. Collaborative Community (Aufgabenform)",
            compensation: "Extrinsische Kompensation",
            processingTime: "Kurze Zeit zur Bearbeitung",
            confidentiality: "Private Aufgabenübermittlung"
        }
    },
    {
        id: 7,
        stage: CROWDSOURCING_STAGES[3],
        question: "Welches Design soll die Lösung haben, um so viele Kunden wie möglich anzusprechen?",
        hints: {
            complexity: "Hohe Komplexität",
            taskProperties: "Konkrete & ausführliche Aufgabenstellung",
            taskForm: "Labor Market o. Collaborative Community (Aufgabenform)",
            compensation: "Extrinsische Kompensation",
            processingTime: "Kurze Zeit zur Bearbeitung",
            confidentiality: "Private Aufgabenübermittlung"
        }
    },
    {
        id: 8,
        stage: CROWDSOURCING_STAGES[3],
        question: "Wie sieht ein Prototyp für die designte Lösung aus?",
        hints: {
            complexity: "Hohe Komplexität",
            taskProperties: "Konkrete & ausführliche Aufgabenstellung",
            taskForm: "Labor Market o. Collaborative Community (Aufgabenform)",
            compensation: "Extrinsische Kompensation",
            processingTime: "Kurze Zeit zur Bearbeitung",
            confidentiality: "Private Aufgabenübermittlung"
        }
    },
    {
        id: 9,
        stage: CROWDSOURCING_STAGES[4],
        question: "Erfüllt unsere Lösung die Qualitätsanforderungen?",
        hints: {
            complexity: "Geringe oder Hohe Komplexität",
            taskProperties: "Konkrete Aufgabenstellung",
            taskForm: "Labor Market (Aufgabenform)",
            compensation: "Intrinsische oder extrinsische Kompensation",
            processingTime: "Kurze Zeit zur Bearbeitung",
            confidentiality: "Private Aufgabenübermittlung"
        }
    },
    {
        id: 10,
        stage: CROWDSOURCING_STAGES[4],
        question: "Erfüllt unsere Lösung generell die Erwartungen der Kunden?",
        hints: {
            complexity: "Geringe oder Hohe Komplexität",
            taskProperties: "Offene / unbegrenzte Aufgabenstellung",
            taskForm: "Labor Market (Aufgabenform)",
            compensation: "Intrinsische oder extrinsische Kompensation",
            processingTime: "Kurze Zeit zur Bearbeitung",
            confidentiality: "Private Aufgabenübermittlung"
        }
    },
    {
        id: 11,
        stage: CROWDSOURCING_STAGES[5],
        question:
            "Auf welchen Kanälen soll die Lösung beworben werden?  Welches Marketingmaterial soll verwendet werden?",
        hints: {
            complexity: "Geringe Komplexität",
            taskProperties: "Folgt...", // TODO ergänzen
            taskForm: "Folgt...", // TODO ergänzen
            compensation: "Intrinsische Kompensation",
            processingTime: "Ausreichend Zeit zur Bearbeitung",
            confidentiality: "Öffentliche Aufgabenübermittlung"
        }
    },
    {
        id: 12,
        stage: CROWDSOURCING_STAGES[5],
        question:
            "Wie teuer kann die Lösung sein, dass Kunden sie kaufen und entstandene Kosten gedeckt werden? " +
            "Auf welchen Kanälen soll die Lösung verkauft werden?",
        hints: {
            complexity: "Geringe Komplexität",
            taskProperties: "Folgt...", // TODO ergänzen
            taskForm: "Folgt...", // TODO ergänzen
            compensation: "Intrinsische Kompensation",
            processingTime: "Ausreichend Zeit zur Bearbeitung",
            confidentiality: "Öffentliche Aufgabenübermittlung"
        }
    },
];
