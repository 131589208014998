import React from 'react';
import PropTypes from "prop-types";
import {withStyles} from '@material-ui/core/styles';
import {Typography} from "@material-ui/core";
import SectionHeading from "components/SectionHeading";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import {ROUTE_CROWD_USERS} from "helpers/Constants";
import {ArrowForwardIos} from "@material-ui/icons";
import ExplainerVideo from "components/ExplainerVideo";

const styles = theme => ({
    root: {},
    videoContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    videoPlayer: {
        marginBottom: theme.spacing(4),
        [theme.breakpoints.up('md')]: {
            width: '50%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        }
    },
    hyperlink: {
        textDecoration: 'none',
        color: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
});

class Hilfe extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const {classes} = this.props;

        const hyperlinkProps = {target: '_blank', className: classes.hyperlink};

        return (
            <React.Fragment>
                <SectionHeading>Crowdsourcing</SectionHeading>

                <Typography align="justify" paragraph>
                    Die Verwendung von Crowdsourcing erfreut sich einer immer größeren Beliebtheit. Auf verschiedenen
                    Crowdsourcing-Plattformen können Unternehmen unterschiedlichste Projekte und Aufgaben an eine
                    qualifizierte Crowd auslagern.
                </Typography>

                <Typography align="justify" paragraph>
                    Insbesondere größere Unternehmen und Konzerne nutzen Crowdsourcing-Plattformen, um fehlende
                    Kompetenzen
                    und Ressourcen auszulagern oder um ihre große und loyale Kundenbasis direkt in den
                    Produktentwicklungsprozess einzubinden.
                </Typography>

                <div className={classes.videoContainer}>
                    <ExplainerVideo className={classes.videoPlayer}/>
                </div>

                <Typography align="center" paragraph>
                    <Button component={Link} to={ROUTE_CROWD_USERS} variant="contained" color="primary"
                            startIcon={<ArrowForwardIos/>}>
                        Crowd finden
                    </Button>
                </Typography>

                <SectionHeading>Crowdsourcing bietet verschiedene Vorteile – u.a.:</SectionHeading>

                <Typography align="justify" paragraph>
                    <ul>
                        <li>Kostenreduzierung</li>
                        <li>Qualitätserhöhung</li>
                        <li>Ausgleich fehlender Kernkompetenzen</li>
                        <li>Steigerung der Effizienz</li>
                        <li>Kreativität</li>
                        <li>Ideenauslösung</li>
                        <li>Schnellere Markteinführung</li>
                        <li>Kundenzentriertes Arbeiten durch direkte Einbindung bestehender/potentieller Kunden</li>
                        <li>Outsider View auf verschiedene Projekte</li>
                    </ul>
                </Typography>

                <SectionHeading>Crowdsourcing für KMUs</SectionHeading>

                <Typography align="justify" paragraph>
                    Das Ziel dieses Gemeinschaftsprojektes der Universität Potsdam und der Technischen Universität
                    München ist
                    es, auch kleinen und mittleren Unternehmen (KMU) einen einfachen und verständlichen Zugang zu
                    verschiedenen Crowdsourcing-Plattformen zu bieten. Durch die Anbindung der Crowd können KMU ihre
                    Wertschöpfungskette flexibilisieren und erhöhen somit ihre Agilität und Wettbewerbsfähigkeit.
                </Typography>

                <Typography align="justify" paragraph>
                    Durch unseren Crowd-Finder erhalten KMU die Möglichkeit die Vielzahl existierender
                    Crowdsourcing-Plattformen direkt ihren individuellen unternehmens- und kontextspezifischen
                    Herausforderungen zuzuordnen. Dies ermöglicht insbesondere bei Aufgaben, die nicht den
                    Kernkompetenzen
                    des Unternehmens entsprechen, den Rückgriff auf externes Wissen der Crowd und somit eine Steigerung
                    der
                    Effizienz von KMU.
                </Typography>

                <SectionHeading>Wie können Sie Crowdsourcing starten?</SectionHeading>

                <Typography align="justify" paragraph>
                    Starten sie den "Crowd-Finder" und wählen Sie eine Phase im Produktentwicklungsprozess aus.
                    Wählen Sie anschließend Ihre Frage aus, bei welcher Ihnen die Crowd behilflich sein kann.
                    Über die Anbieter Liste erhalten sie einen Überblick aller für Sie geeigneten Crowd-Anbieter.
                </Typography>

                <Typography align="left" paragraph>
                    Viel Spass beim Outsourcen!<br/>
                    Das Crowd-Finder Team
                </Typography>

                <SectionHeading>Publikationen</SectionHeading>

                <Typography align="left" paragraph>
                    Thim, C., Ullrich, A., Eigelshoven, F., Gronau, N. and Ritter, A.-C.<br/>
                    <a {...hyperlinkProps}
                       href="https://lswi.de/publikationen/journalbeitraege/agiles-projektbasiertes-crowdsourcing-zugleich-herausforderung-und-loesungsansatz-fuer-kmu"
                    >
                        <i>Agiles projektbasiertes Crowdsourcing: Zugleich Herausforderung und Lösungsansatz für KMU</i>
                    </a><br/>
                    Industrie 4.0 Management, <b>2020</b>, Vol. 36(6), pp. 9-13
                </Typography>
                <Typography align="left" paragraph>
                    Ritter, A.-C., Hoffmann, A.<br/>
                        <i>Crowdsourcing the Innovation Process of Firms:  A Systematic Literature Review. Accepted for presentation at the OUI</i>
                    <br/>
                    18th Open & User Innovation Conference, Virtual
                </Typography>
                <Typography align="left" paragraph>
                    Eigelshoven, F., Ullrich, A., Thim, C.<br/>
                        <i>Studying the effects of Industry 4.0 on Crowdsourcing using a text-mining approach. Accepted for presentation at the OUI</i>
                    <br/>
                    18th Open & User Innovation Conference, Virtual
                </Typography>

            </React.Fragment>
        );
    }
}

export default withStyles(styles)(Hilfe);
