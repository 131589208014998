import React from 'react';
import PropTypes from "prop-types";
import {withStyles} from '@material-ui/core/styles';
import {Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel} from "@material-ui/core";

const styles = theme => ({
    root: {},
});

class CrowdFilter extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        optionsAvailable: PropTypes.array.isRequired,
        optionsSelected: PropTypes.array.isRequired,
        title: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
    }

    render() {
        const {title, optionsAvailable, optionsSelected, onChange} = this.props;

        const createChangeHandler = (option) => (event) => onChange(event, option);

        return (
            <FormControl component="fieldset" fullWidth>
                <FormLabel>{title}</FormLabel>
                <FormGroup>
                    {optionsAvailable.map((option, index) => (
                        <FormControlLabel key={index}
                                          control={<Checkbox checked={optionsSelected.includes(option)}
                                                             onChange={createChangeHandler(option)}/>}
                                          label={option} />
                    ))}
                </FormGroup>
            </FormControl>
        );
    }
}

export default withStyles(styles)(CrowdFilter);
