import React from 'react';
import PropTypes from "prop-types";
import {withStyles} from '@material-ui/core/styles';
import {Grid, Tooltip, Typography} from "@material-ui/core";

import imgGoal from "media/Icons/goal.png";
import imgAskQuestion from "media/Icons/askQuestion.png";
import imgCrowd from "media/Icons/crowd.png";
import imgReportCard from "media/Icons/reportCard.png";
import imgMultipleInputs from "media/Icons/multipleInputs.png";
import CompanyLogo from "components/CompanyLogo";
import {Euro} from "@material-ui/icons";
import {CROWDSOURCING_STAGES} from "helpers/Constants";

const styles = theme => ({
    rightColumn: {
        whiteSpace: 'pre-wrap'
    },
    icon: {
        display: 'block',
        width: '100%',
    },
    materialIcon: {
        width: '100%',
        height: 'auto',
    },
    titleGrid: {
        alignItems: 'center',
    }
});

class BestPracticeDetails extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired
    }

    render() {
        const {classes, hideNumbers} = this.props;
        const {
            crowd_logo,
            title,
            activity,
            client_logo,
            client_name,
            goal,
            task,
            task_form,
            results,
            numbers,
            compensation
        } = this.props.data;
        const crowd_name = this.props.data.crowd.name;

        const leftColumnProps = {
            xs: 2,
            sm: 1
        }

        const rightColumnProps = {
            xs: 10,
            sm: 11,
            className: classes.rightColumn
        }

        return (
            <React.Fragment>
                <Grid container spacing={2} className={classes.titleGrid}>
                    <Grid item xs={2}>
                        <CompanyLogo name={crowd_name} logo={crowd_logo}/>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="h6" align="center">
                            {title}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <CompanyLogo name={client_name} logo={client_logo}/>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item {...leftColumnProps}>
                        <Tooltip title="Stufe & Frage" arrow>
                            <img src={CROWDSOURCING_STAGES.find(x => x.id === activity.stage.id).icon}
                                 alt="Stufe & Frage"
                                 className={classes.icon}
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item {...rightColumnProps}>
                        {activity.stage.description}: {activity.question}
                    </Grid>

                    <Grid item {...leftColumnProps}>
                        <Tooltip title="Ziel" arrow>
                            <img src={imgGoal} alt="Ziel" className={classes.icon}/>
                        </Tooltip>
                    </Grid>
                    <Grid item {...rightColumnProps}>
                        {goal}
                    </Grid>

                    <Grid item {...leftColumnProps}>
                        <Tooltip title="Konkrete Aufgabenstellung" arrow>
                            <img src={imgAskQuestion} alt="Konkrete Aufgabenstellung" className={classes.icon}/>
                        </Tooltip>
                    </Grid>
                    <Grid item {...rightColumnProps}>
                        {task}
                    </Grid>

                    <Grid item {...leftColumnProps}>
                        <Tooltip title="Art des durchgeführten Crowdsourcings" arrow>
                            <img src={imgCrowd} alt="Art des durchgeführten Crowdsourcings" className={classes.icon}/>
                        </Tooltip>
                    </Grid>
                    <Grid item {...rightColumnProps}>
                        {task_form}
                    </Grid>

                    <Grid item {...leftColumnProps}>
                        <Tooltip title="Ergebnis des Crowdsourcings" arrow>
                            <img src={imgReportCard} alt="Ergebnis des Crowdsourcings" className={classes.icon}/>
                        </Tooltip>
                    </Grid>
                    <Grid item {...rightColumnProps}>
                        {results}
                    </Grid>

                    {!hideNumbers && numbers && (<>
                        <Grid item {...leftColumnProps}>
                            <Tooltip title="Einreichungen / Teilnahmezahlen / Partizipation" arrow>
                                <img src={imgMultipleInputs}
                                     alt="Einreichungen / Teilnahmezahlen / Partizipation"
                                     className={classes.icon}
                                />
                            </Tooltip>
                        </Grid>
                        <Grid item {...rightColumnProps}>
                            {numbers}
                        </Grid>
                    </>)}

                    {!hideNumbers && compensation && (<>
                        <Grid item {...leftColumnProps}>
                            <Tooltip title="Kompensation" arrow>
                                <Euro alt="Kompensation" className={classes.materialIcon}/>
                            </Tooltip>
                        </Grid>
                        <Grid item {...rightColumnProps}>
                            {compensation}
                        </Grid>
                    </>)}
                </Grid>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(BestPracticeDetails);
