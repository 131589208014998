import React from 'react';
import PropTypes from "prop-types";
import {withStyles} from '@material-ui/core/styles';
import {Paper} from "@material-ui/core";
import {DataGrid} from "@material-ui/data-grid";
import SectionHeading from "components/SectionHeading";
import {getActivities, getExpertises, getLanguages, getParticipants, getStages, getTaskForms} from "helpers/api";

const styles = theme => ({
    root: {},
});

const languageColumns = [
    {field: 'id', headerName: 'ID', width: 70,},
    {field: 'shorthand', headerName: 'Kürzel', width: 100,},
    {field: 'language', headerName: 'Sprache', flex: 1,},
];

const taskFormColumns = [
    {field: 'id', headerName: 'ID', width: 70,},
    {field: 'description', headerName: 'Aufgabenform', flex: 1,},
];

const expertiseColumns = [
    {field: 'id', headerName: 'ID', width: 70,},
    {field: 'description', headerName: 'Community-Expertise', flex: 1,},
];

const activityColumns = [
    {field: 'id', headerName: 'ID', width: 70,},
    {field: 'stage_id', headerName: 'Stufe', width: 100,},
    {field: 'description', headerName: 'Bezeichnung', width: 200,},
    {field: 'question', headerName: 'Frage', flex: 1,},
];

const stageColumns = [
    {field: 'id', headerName: 'ID', width: 70,},
    {field: 'description', headerName: 'Stufe', flex: 1,},
];

const participantsColumns = [
    {field: 'id', headerName: 'ID', width: 70,},
    {field: 'description', headerName: 'Teilnehmer', flex: 1,},
];

class AdminMasterData extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);
        this.state = {
            languages: [],
            task_forms: [],
            expertises: [],
            activities: [],
            stages: [],
            participants: [],
        };
    }

    componentDidMount() {
        getLanguages()
            .then(languages => this.setState({languages}))
            .catch(error => console.log(error));
        getTaskForms()
            .then(task_forms => this.setState({task_forms}))
            .catch(error => console.log(error));
        getExpertises()
            .then(expertises => this.setState({expertises}))
            .catch(error => console.log(error));
        getStages()
            .then(stages => this.setState({stages}))
            .catch(error => console.log(error));
        getActivities()
            .then(activities => this.setState({activities}))
            .catch(error => console.log(error));
        getParticipants()
            .then(participants => this.setState({participants}))
            .catch(error => console.log(error));
    }

    render() {
        const {languages, task_forms, expertises, stages, activities, participants} = this.state;
        return (
            <React.Fragment>
                <SectionHeading>Stufen</SectionHeading>
                <Paper>
                    <DataGrid columns={stageColumns} rows={stages} pageSize={10} autoHeight/>
                </Paper>

                <SectionHeading>Aktivitäten</SectionHeading>
                <Paper>
                    <DataGrid columns={activityColumns} rows={activities} pageSize={10} autoHeight/>
                </Paper>

                <SectionHeading>Community-Expertisen</SectionHeading>
                <Paper>
                    <DataGrid columns={expertiseColumns} rows={expertises} pageSize={10} autoHeight/>
                </Paper>

                <SectionHeading>Teilnehmer</SectionHeading>
                <Paper>
                    <DataGrid columns={participantsColumns} rows={participants} pageSize={10} autoHeight/>
                </Paper>

                <SectionHeading>Aufgabenformen</SectionHeading>
                <Paper>
                    <DataGrid columns={taskFormColumns} rows={task_forms} pageSize={10} autoHeight/>
                </Paper>

                <SectionHeading>Sprachen</SectionHeading>
                <Paper>
                    <DataGrid columns={languageColumns} rows={languages} pageSize={10} autoHeight/>
                </Paper>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(AdminMasterData);
