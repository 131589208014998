import React from 'react';
import PropTypes from "prop-types";
import {withStyles} from '@material-ui/core/styles';
import {Backdrop, CircularProgress, Fade} from "@material-ui/core";
import clsx from "clsx";

const styles = theme => ({
    root: {
        zIndex: theme.zIndex.drawer +1,
        color: '#fff',
    },
});

class FullscreenProgress extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        open: PropTypes.bool.isRequired,
        style: PropTypes.object,
        className: PropTypes.string,
    }

    render() {
        const {classes, style, className, open} = this.props;

        return (
            <Fade
                in={open}
                unmountOnExit
                style={{...style, transitionDelay: open ? '800ms' : '0ms'}}
                className={clsx(classes.root, className)}
            >
                <Backdrop
                    open={open}
                >
                    <CircularProgress
                        color="inherit"
                    />
                </Backdrop>
            </Fade>
        );
    }
}

export default withStyles(styles)(FullscreenProgress);
