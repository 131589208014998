import axios from "axios";
import {eventBus} from "helpers/EventBus";

function handleHttpErrors(error) {
    if(error.response && error.response.status === 401) {
        eventBus.dispatch('HttpErrorUnauthorized');
    }
}

export function fileURL(fileName) {
    return (process.env.REACT_APP_FILESERVER_BASE_URL || "http://localhost:8080/files") + "/" + fileName;
}

export function getBestPractices(auth = false) {
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_URL + "/best-practices", auth ? {withCredentials: true} : {})
            .then((response) => {
                resolve(response.data.data);
            })
            .catch((error) => {
                handleHttpErrors(error);
                reject(error);
            });
    })
}

export function getBestPractice(id, auth = false) {
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_URL + "/best-practices/" + id, auth ? {withCredentials: true} : {})
            .then((response) => {
                resolve(response.data.data);
            })
            .catch((error) => {
                handleHttpErrors(error);
                reject(error);
            });
    })
}

export function postBestPractice(data) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_URL + "/best-practices", data, {withCredentials: true})
            .then(() => {
                resolve();
            })
            .catch((error) => {
                handleHttpErrors(error);
                reject(error);
            });
    });
}

export function putBestPractice(id, data) {
    return new Promise((resolve, reject) => {
        axios.put(process.env.REACT_APP_API_URL + "/best-practices/" + id, data, {withCredentials: true})
            .then(() => {
                resolve();
            })
            .catch((error) => {
                handleHttpErrors(error);
                reject(error);
            });
    });
}

export function deleteBestPractice(id) {
    return new Promise((resolve, reject) => {
        axios.delete(process.env.REACT_APP_API_URL + "/best-practices/" + id, {withCredentials: true})
            .then(() => {
                resolve();
            })
            .catch((error) => {
                handleHttpErrors(error);
                reject(error);
            });
    });
}

export function getCrowds(auth = false) {
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_URL + "/crowds", auth ? {withCredentials: true} : {})
            .then((response) => {
                resolve(response.data.data);
            })
            .catch((error) => {
                handleHttpErrors(error);
                reject(error);
            });
    })
}

export function getCrowd(id, auth = false) {
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_URL + "/crowds/" + id, auth ? {withCredentials: true} : {})
            .then((response) => {
                resolve(response.data.data);
            })
            .catch((error) => {
                handleHttpErrors(error);
                reject(error);
            });
    })
}

export function deleteCrowd(id) {
    return new Promise((resolve, reject) => {
        axios.delete(process.env.REACT_APP_API_URL + "/crowds/" + id, {withCredentials: true})
            .then(() => {
                resolve();
            })
            .catch((error) => {
                handleHttpErrors(error);
                reject(error);
            });
    });
}

export function postCrowd(data) {
    return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_API_URL + "/crowds", data, {withCredentials: true})
            .then(() => {
                resolve();
            })
            .catch((error) => {
                handleHttpErrors(error);
                reject(error);
            });
    });
}

export function putCrowd(id, data) {
    return new Promise((resolve, reject) => {
        axios.put(process.env.REACT_APP_API_URL + "/crowds/" + id, data, {withCredentials: true})
            .then(() => {
                resolve();
            })
            .catch((error) => {
                handleHttpErrors(error);
                reject(error);
            });
    });
}

export function getUser() {
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_URL + "/user", {withCredentials: true})
            .then((response) => {
                resolve(response.data.user);
            })
            .catch((error) => {
                handleHttpErrors(error);
                reject(error);
            });
    })
}

export function getLanguages() {
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_URL + "/languages", {withCredentials: true})
            .then((response) => {
                resolve(response.data.data);
            })
            .catch((error) => {
                handleHttpErrors(error);
                reject(error);
            });
    })
}

export function getTaskForms() {
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_URL + "/task-forms", {withCredentials: true})
            .then((response) => {
                resolve(response.data.data);
            })
            .catch((error) => {
                handleHttpErrors(error);
                reject(error);
            });
    })
}

export function getExpertises() {
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_URL + "/expertises", {withCredentials: true})
            .then((response) => {
                resolve(response.data.data);
            })
            .catch((error) => {
                handleHttpErrors(error);
                reject(error);
            });
    })
}

export function getStages() {
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_URL + "/stages", {withCredentials: true})
            .then((response) => {
                resolve(response.data.data);
            })
            .catch((error) => {
                handleHttpErrors(error);
                reject(error);
            });
    })
}

export function getActivities() {
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_URL + "/activities", {withCredentials: true})
            .then((response) => {
                resolve(response.data.data);
            })
            .catch((error) => {
                handleHttpErrors(error);
                reject(error);
            });
    })
}

export function getParticipants() {
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_URL + "/participants", {withCredentials: true})
            .then((response) => {
                resolve(response.data.data);
            })
            .catch((error) => {
                handleHttpErrors(error);
                reject(error);
            });
    })
}

export function getFiles() {
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_URL + "/files", {withCredentials: true})
            .then((response) => {
                resolve(response.data.data);
            })
            .catch((error) => {
                handleHttpErrors(error);
                reject(error);
            });
    })
}

export function postFile(file, onUploadProgress) {
    return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append('file', file);

        axios.post(process.env.REACT_APP_API_URL + "/files", formData,
            {
                withCredentials: true,
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: onUploadProgress,
            })
            .then(() => {
                resolve();
            })
            .catch((error) => {
                handleHttpErrors(error);
                reject(error);
            });
    });
}

export function deleteFile(file) {
    return new Promise((resolve, reject) => {
        axios.delete(process.env.REACT_APP_API_URL + "/files/" + file, {withCredentials: true})
            .then(() => {
                resolve();
            })
            .catch((error) => {
                handleHttpErrors(error);
                reject(error);
            });
    });
}
