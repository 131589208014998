import React from 'react';
import ReactDOM from 'react-dom';
import { Switch, Route, BrowserRouter } from 'react-router-dom'
import {CssBaseline} from "@material-ui/core";
import {createMuiTheme, ThemeProvider} from "@material-ui/core/styles";

import ScrollToTop from "helpers/ScrollToTop";
import Main from "layouts/Main";
import AdminMain from "layouts/AdminMain";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#2f5597',
        },
        secondary: {
            main: '#97712f',
        },
        background: {
            default: '#ffffff',
            paper: '#fafafa'
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        }
    }
});

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <BrowserRouter>
                <ScrollToTop/>
                <Switch>
                    <Route path="/admin" component={AdminMain}/>
                    <Route path='/' component={Main}/>
                </Switch>
            </BrowserRouter>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
