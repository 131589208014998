import React from 'react';
import PropTypes from "prop-types";
import {withStyles} from '@material-ui/core/styles';
import {Container, Grid} from "@material-ui/core";
import imgLogo from "media/hexagon.jpg";
import Navbar from "components/Navbar";
import {Route, Switch} from "react-router-dom";
import Footer from "components/Footer";
import clsx from "clsx";

const styles = theme => ({
    root: {},
    logoContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    logo: {
        alignSelf: 'flex-start',
        display: 'block',
        [theme.breakpoints.down('xs')]: {
            width: '66%'
        },
        [theme.breakpoints.only('sm')]: {
            width: '50%'
        },
        [theme.breakpoints.up('md')]: {
            width: '33%'
        },
    },
    contentContainer: {
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: '1rem',
        paddingBottom: 100,
        hyphens: 'auto',
    },
    footerContainer: {
        marginBottom: 16+8,
    },
});

class BaseLayout extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        className: PropTypes.string,
        style: PropTypes.object,
        navigation: PropTypes.array.isRequired,
        routes: PropTypes.array.isRequired,
    }

    render() {
        const {classes, className, style, navigation, routes} = this.props;

        return (
            <Container maxWidth="lg" style={style} className={clsx(classes.root, className)}>
                <Grid container spacing={0}>
                    <Grid item xs={12} className={classes.logoContainer}>
                        <img src={imgLogo} alt="Logo" className={classes.logo}/>
                    </Grid>

                    <Grid item xs={12}>
                        <Navbar locations={navigation}/>
                    </Grid>

                    <Grid item xs={12} className={classes.contentContainer}>
                        <Switch>
                            {routes.map((item, index) => (
                                <Route exact path={item.path} component={item.component} key={index}/>
                            ))}
                        </Switch>
                    </Grid>

                    <Grid item xs={12} className={classes.footerContainer}>
                        <Footer/>
                    </Grid>
                </Grid>
            </Container>
        );
    }
}

export default withStyles(styles)(BaseLayout);
