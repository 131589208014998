import React from 'react';
import PropTypes from "prop-types";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import {Close, GetApp} from "@material-ui/icons";

import SectionHeading from "components/SectionHeading";
import StageChoicer from "components/StageChoicer";
import BestPracticeCardSmall from "components/BestPracticeCardSmall";
import {CROWDSOURCING_STAGES} from "helpers/Constants";
import BestPracticeDetails from "components/BestPracticeDetails";
import {fileURL, getBestPractices} from "helpers/api";
import FullscreenProgress from "components/FullscreenProgress";
import NetworkErrorDialog from "components/NetworkErrorDialog";

const styles = theme => ({
    dialogContent: {
        padding: theme.spacing(2, 4, 2),
    },
    dialogActions: {
        padding: theme.spacing(2, 4, 2),
        justifyContent: 'space-between',
        [theme.breakpoints.down("xs")]: {
            flexDirection: 'column-reverse',
        },
        [theme.breakpoints.up("sm")]: {
            flexDirection: 'row-reverse',
        },
    },
    dialogButton: {
        [theme.breakpoints.down("xs")]: {
            margin: theme.spacing(1),
            width: '100%',
        }
    },
});

class BestPractice extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);
        this.state = {
            stage: -1,
            bestPractices: [],
            showDetails: false,
            details: null,
            isLoading: true,
            networkError: false,
        };
        this.onStageChange = this.onStageChange.bind(this);
        this.handleCardClick = this.handleCardClick.bind(this);
        this.handleCloseDetails = this.handleCloseDetails.bind(this);
    }

    componentDidMount() {
        getBestPractices()
            .then((result) => {
                this.setState({
                    isLoading: false,
                    bestPractices: result,
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    isLoading: false,
                    networkError: true,
                });
            });

        if (typeof this.props.location.state !== 'undefined') {
            if (typeof this.props.location.state.stage !== 'undefined') {
                this.setState({stage: this.props.location.state.stage})
            }
        }
    }

    onStageChange(newStage) {
        this.setState({stage: newStage});
    }

    handleCardClick(id) {
        this.setState({
            showDetails: true,
            details: this.state.bestPractices.find(x => x.id === id)
        });
    }

    handleCloseDetails() {
        this.setState({
            showDetails: false
        });
    }

    render() {
        const {classes} = this.props;
        const {stage, details, showDetails, isLoading, networkError} = this.state;

        let bestPractices;
        if (stage < 0) {
            bestPractices = this.state.bestPractices;
        } else {
            bestPractices = this.state.bestPractices.filter(x => x.activity.stage.id === stage);
        }

        return (
            <React.Fragment>
                <FullscreenProgress open={isLoading}/>
                <NetworkErrorDialog open={networkError}/>

                <SectionHeading>Filtern Sie nach der relevanten Stufe</SectionHeading>

                <StageChoicer stage={stage} onChange={this.onStageChange}/>

                <SectionHeading>
                    Praxisbeispiele
                    {stage === CROWDSOURCING_STAGES[0].id && <>: {CROWDSOURCING_STAGES[0].description}</>}
                    {stage === CROWDSOURCING_STAGES[1].id && <>: {CROWDSOURCING_STAGES[1].description}</>}
                    {stage === CROWDSOURCING_STAGES[2].id && <>: {CROWDSOURCING_STAGES[2].description}</>}
                    {stage === CROWDSOURCING_STAGES[3].id && <>: {CROWDSOURCING_STAGES[3].description}</>}
                    {stage === CROWDSOURCING_STAGES[4].id && <>: {CROWDSOURCING_STAGES[4].description}</>}
                    {stage === CROWDSOURCING_STAGES[5].id && <>: {CROWDSOURCING_STAGES[5].description}</>}
                </SectionHeading>

                <Grid container spacing={4}>
                    {bestPractices.map((item) =>
                        <Grid item xs={12} sm={6} md={3} key={item.id}>
                            <BestPracticeCardSmall data={item} onClick={this.handleCardClick}/>
                        </Grid>
                    )}
                </Grid>

                <Dialog
                    open={showDetails}
                    onClose={this.handleCloseDetails}
                    scroll="body"
                    maxWidth="sm"
                >
                    {details && (<>
                        <DialogContent className={classes.dialogContent}>
                            <BestPracticeDetails data={details}/>
                        </DialogContent>
                        <DialogActions className={classes.dialogActions}>
                            <Button
                                onClick={this.handleCloseDetails}
                                startIcon={<Close/>}
                                color="primary"
                                variant="outlined"
                                className={classes.dialogButton}
                            >
                                Schließen
                            </Button>
                            {details.pdf_document &&
                                <Button startIcon={<GetApp/>}
                                        href={fileURL(details.pdf_document)}
                                        target="_blank"
                                        variant="contained"
                                        color="primary"
                                        className={classes.dialogButton}
                                >
                                    Download PDF
                                </Button>
                            }
                        </DialogActions>
                    </>)}
                </Dialog>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(BestPractice);
