import React from 'react';
import PropTypes from "prop-types";
import {withStyles} from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import {Paper} from "@material-ui/core";

const styles = theme => ({
    root: {
        textAlign: 'center',
        marginTop: '2rem',
        marginBottom: '1rem',
    }
});

class SectionHeading extends React.Component {
    render() {
        if (this.props.hidden) return null;

        const {classes, children} = this.props;

        return (
            <Paper className={classes.root}>
                <Typography variant="h6">
                    {children}
                </Typography>
            </Paper>
        );
    }
}

SectionHeading.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(SectionHeading);
