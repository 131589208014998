import React from 'react';
import PropTypes from "prop-types";
import {withStyles} from '@material-ui/core/styles';
import {Button, Grid, Paper, Tooltip} from "@material-ui/core";
import {DataGrid} from "@material-ui/data-grid";
import {getBestPractices, deleteBestPractice} from "helpers/api";
import {Add, CheckBoxOutlineBlankOutlined, CheckBoxOutlined, Delete, Edit, Home, Public} from "@material-ui/icons";
import {ROUTE_ADMIN_BEST_PRACTICES_EDIT} from "helpers/Constants";
import ConfirmDeleteDialog from "components/ConfirmDeleteDialog";

const styles = theme => ({
    root: {},
    buttonCreate: {
        marginTop: theme.spacing(2),
    },
    buttonContainer: {
        textAlign: 'center',
    },
    tableContainer: {
        marginTop: theme.spacing(4),
    },
    buttonDelete: {
        marginLeft: theme.spacing(2),
    },
    paperTable: {
        height: 650,
    }
});

const columns = [
    {field: 'id', headerName: 'ID', width: 70,},
    {
        field: 'crowd_name',
        headerName: 'Crowd',
        width: 200,
        valueGetter: (params) => params.getValue('crowd').name,
    },
    {field: 'client_name', headerName: 'Kunde', width: 200,},
    {field: 'title', headerName: 'Titel', flex: 1,},
    {
        field: 'published',
        description: 'Veröffentlicht',
        width: 90,
        renderHeader: () => <Tooltip title={"Veröffentlicht"} arrow><Public/></Tooltip>,
        renderCell: (params) => (
            params.value ?
            <Tooltip title={"öffentlich"} arrow><CheckBoxOutlined/></Tooltip> :
            <Tooltip title={"nicht öffentlich"} arrow><CheckBoxOutlineBlankOutlined/></Tooltip>
        )
    },
    {
        field: 'front_page',
        description: 'Auf der Startseite anzeigen',
        width: 90,
        renderHeader: () => <Tooltip title={"Auf der Startseite anzeigen"} arrow><Home/></Tooltip>,
        renderCell: (params) => (
            params.value ?
                <Tooltip title={"Auf der Startseite"} arrow><CheckBoxOutlined/></Tooltip> :
                <Tooltip title={"Nicht auf der Startseite"} arrow><CheckBoxOutlineBlankOutlined/></Tooltip>
        )
    },
];

class AdminBestPractices extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);
        this.state = {
            bestPractices: [],
            itemsSelected: undefined,
            isLoading: true,
            confirmDeleteDialogOpen: false,
            deleteItemDescription: '',
        };
        this.handleCreate = this.handleCreate.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleSelectionModelChange = this.handleSelectionModelChange.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleConfirmDelete = this.handleConfirmDelete.bind(this);
        this.handleDismissDelete = this.handleDismissDelete.bind(this);
    }

    componentDidMount() {
        this.updateBestPractices();
    }

    updateBestPractices() {
        this.setState({isLoading: true, itemsSelected: []});
        getBestPractices(true)
            .then(bestPractices => this.setState({bestPractices, isLoading: false}))
            .catch(error => console.log(error));
    }

    handleCreate() {
        this.props.history.push(ROUTE_ADMIN_BEST_PRACTICES_EDIT);
    }

    handleEdit() {
        this.props.history.push(ROUTE_ADMIN_BEST_PRACTICES_EDIT + "/" + this.state.itemsSelected[0]);
    }

    handleDelete() {
        const selectedItem = this.state.bestPractices.find(x => x.id === parseInt(this.state.itemsSelected[0]))
        this.setState({
            confirmDeleteDialogOpen: true,
            deleteItemDescription: selectedItem.title,
        });
    }

    handleConfirmDelete() {
        this.setState({
            confirmDeleteDialogOpen: false,
            deleteItemDescription: '',
        });
        deleteBestPractice(this.state.itemsSelected[0])
            .then(() => {
                this.updateBestPractices();
            })
            .catch((error) => {
                console.log(error);
            });
    }

    handleDismissDelete() {
        this.setState({
            itemsSelected: [],
            confirmDeleteDialogOpen: false,
            deleteItemDescription: '',
        });
    }

    handleSelectionModelChange(newSelection) {
        this.setState({itemsSelected: newSelection.selectionModel});
    }

    render() {
        const {classes} = this.props;
        const {bestPractices, itemsSelected, isLoading, confirmDeleteDialogOpen, deleteItemDescription} = this.state;

        return (
            <Grid container spacing={4} className={classes.root}>
                <Grid item xs={12} className={classes.tableContainer}>
                    <Paper className={classes.paperTable}>
                        <DataGrid columns={columns}
                                  rows={bestPractices}
                                  loading={isLoading}
                                  selectionModel={itemsSelected}
                                  onSelectionModelChange={this.handleSelectionModelChange}
                                  pageSize={10}
                                  sortModel={[{field: 'id', sort: 'asc'}]}
                                  disableColumnMenu
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12} container justify="space-between">
                    <Grid item>
                        <Button color="primary"
                                startIcon={<Edit/>}
                                variant="contained"
                                onClick={this.handleEdit}
                                disabled={!itemsSelected || itemsSelected.length < 1}>
                            Bearbeiten
                        </Button>
                        <Button color="primary"
                                startIcon={<Delete/>}
                                variant="outlined"
                                onClick={this.handleDelete}
                                className={classes.buttonDelete}
                                disabled={!itemsSelected || itemsSelected.length < 1}>
                            Löschen
                        </Button>
                        <ConfirmDeleteDialog
                            open={confirmDeleteDialogOpen}
                            onConfirm={this.handleConfirmDelete}
                            onDismiss={this.handleDismissDelete}
                            itemDescription={deleteItemDescription}
                        />
                    </Grid>
                    <Grid item>
                        <Button variant="contained"
                                color="primary"
                                onClick={this.handleCreate}
                                startIcon={<Add/>}>
                            Neues Praxisbeispiel erstellen
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(AdminBestPractices);
