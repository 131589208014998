import React from 'react';
import PropTypes from "prop-types";
import {withStyles} from '@material-ui/core/styles';
import {
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel, Typography
} from "@material-ui/core";
import {FilterList, Language} from "@material-ui/icons";
import CrowdFilter from "components/CrowdFilter";

const styles = theme => ({
    root: {},
    filterContainer: {
        padding: theme.spacing(1, 2),
    },
    filterHeading: {
        marginBottom: theme.spacing(2),
    }
});

const headCells = [
    {id: 'name', label: 'Anbieter', sortable: true},
    {id: 'expertises', label: 'Community-Expertise', sortable: true},
    {id: 'participants', label: 'Crowd', sortable: true},
    {id: 'task_forms', label: 'Form', sortable: true},
    {id: 'languages', label: 'Sprache', sortable: true},
    {id: 'website', label: 'Kontakt', sortable: false},
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(orderBy, order) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function getFilterOptions(array, key) {
    const options = [];
    for (const item of array) {
        if (Array.isArray(item[key])) {
            for (const child of item[key]) {
                if (child && !options.includes(child)) options.push(child);
            }
        } else {
            if (item[key] && !options.includes(item[key])) options.push(item[key]);
        }
    }
    return options;
}

function createFilter(rows, name, columnName, title) {
    return {
        name,
        columnName,
        title,
        options: getFilterOptions(rows, columnName),
    }
}

class CrowdTable extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        rows: PropTypes.array.isRequired,
    }

    constructor(props) {
        super(props);
        this.state = {
            orderBy: headCells[0].id,
            order: 'asc',
            filterExpertises: [],
            filterTaskForms: [],
            filterLanguages: [],
            filterParticipants: []
        };
        this.handleRequestSort = this.handleRequestSort.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // reset filter selections on data change
        if (JSON.stringify(this.props.rows) !== JSON.stringify(prevProps.rows)) {
            this.setState({
                filterExpertises: [],
                filterTaskForms: [],
                filterLanguages: [],
                filterParticipants: []
            })
        }
    }

    handleRequestSort(event, property) {
        const {orderBy, order} = this.state;
        const isAsc = orderBy === property && order === 'asc';
        this.setState({
            orderBy: property,
            order: isAsc ? 'desc' : 'asc',
        });
    }

    handleFilterChange(event, option, name) {
        const options = this.state[name];

        const index = options.indexOf(option);
        if (index > -1) {
            options.splice(index, 1);
        } else {
            options.push(option);
        }
        this.setState({[name]: options})
    }

    render() {
        const {classes, rows} = this.props;
        const {orderBy, order} = this.state;
        const {filterExpertises, filterTaskForms, filterParticipants, filterLanguages} = this.state;

        const createSortHandler = (property) => (event) => this.handleRequestSort(event, property);
        const createFilterHandler = (name) => (event, option) => this.handleFilterChange(event, option, name);

        const filters = [
            createFilter(rows,'filterExpertises','expertises','Community-Expertise'),
            createFilter(rows,'filterTaskForms','task_forms','Form'),
            createFilter(rows,'filterParticipants','participants','Crowd'),
            createFilter(rows,'filterLanguages','languages','Sprache'),
        ];

        return (
            <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                    <Paper className={classes.filterContainer}>
                        <Typography variant="h6" className={classes.filterHeading}><FilterList/> FILTER</Typography>
                        <Grid container spacing={2}>
                            {filters.map(filter => filter.options.length > 0 && (
                                <Grid item xs={12} sm={4} md={12}>
                                    <CrowdFilter title={filter.title}
                                                 optionsAvailable={filter.options}
                                                 optionsSelected={this.state[filter.name]}
                                                 onChange={createFilterHandler(filter.name)}/>
                                </Grid>
                            ))}
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={9}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {headCells.map(headCell => (
                                        <TableCell key={headCell.label}
                                                   sortDirection={orderBy === headCell.id ? order : false}>
                                            {headCell.sortable ? (
                                                    <TableSortLabel active={orderBy === headCell.id}
                                                                    direction={orderBy === headCell.id ? order : 'asc'}
                                                                    onClick={createSortHandler(headCell.id)}>
                                                        {headCell.label}
                                                    </TableSortLabel>)
                                                : headCell.label
                                            }
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {stableSort(rows, getComparator(orderBy, order))
                                    .filter(x => filterExpertises.length === 0 ||
                                        x.expertises.some(y => filterExpertises.includes(y)))
                                    .filter(x => filterParticipants.length === 0 ||
                                        filterParticipants.includes(x.participants))
                                    .filter(x => filterLanguages.length === 0 ||
                                        x.languages.some(y => filterLanguages.includes(y)))
                                    .filter(x => filterTaskForms.length === 0 ||
                                        x.task_forms.some(y => filterTaskForms.includes(y)))
                                    .map((row) => (
                                        <TableRow key={row.id}>
                                            <TableCell>{row.name}</TableCell>
                                            <TableCell>
                                                {row.expertises.length > 0 ? row.expertises.join(", ") : "-"}
                                            </TableCell>
                                            <TableCell>{row.participants}</TableCell>
                                            <TableCell>{row.task_forms.join(", ")}</TableCell>
                                            <TableCell>{row.language_codes.join(", ")}</TableCell>
                                            <TableCell>
                                                <IconButton href={row.website} target="_blank">
                                                    <Language/>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(CrowdTable);
