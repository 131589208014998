import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import PropTypes from "prop-types";
import clsx from "clsx";
import {BigPlayButton, Player} from "video-react";
import 'video-react/dist/video-react.css';

import video from "media/videos/apc-projekt.mp4";
import thumbnail from "media/videos/apc-projekt.png";

const styles = theme => ({
    root: {
        width: '100%',
    },
});

class ExplainerVideo extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired
    }

    render() {
        const {classes, className} = this.props;

        return (
            <div className={clsx(classes.root, className)}>
                <Player src={video} poster={thumbnail} playsInline>
                    <BigPlayButton position="center"/>
                </Player>
            </div>
        );
    }
}

export default withStyles(styles)(ExplainerVideo);
