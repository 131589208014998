import React from 'react';
import PropTypes from "prop-types";
import {withStyles} from '@material-ui/core/styles';
import {Button, CircularProgress, Grid, IconButton, Paper, Tooltip} from "@material-ui/core";
import {deleteFile, fileURL, getFiles, postFile} from "helpers/api";
import {DataGrid} from "@material-ui/data-grid";
import {Delete, GetApp, Publish} from "@material-ui/icons";
import ConfirmDeleteDialog from "components/ConfirmDeleteDialog";

const styles = theme => ({
    root: {},
    buttonCreate: {
        marginTop: theme.spacing(2),
    },
    buttonContainer: {
        textAlign: 'center',
    },
    tableContainer: {
        marginTop: theme.spacing(4),
    },
    paperTable: {
        height: 650,
    },
    buttonWrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    }
});

const columns = [
    {
        field: 'filename',
        headerName: 'Dateiname',
        flex: 1,
        renderCell: (params) => (<>
            <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                <div>{params.value}</div>
                <div>
                    <Tooltip title={"Vorschau"} arrow>
                        <IconButton href={fileURL(params.value)} target="_blank">
                            <GetApp/>
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
        </>)
    },
];

class AdminFiles extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);
        this.state = {
            files: [],
            isLoading: true,
            filesSelected: [],
            confirmDeleteDialogOpen: false,
            deleteItemDescription: '',
            uploadInProgress: false,
            uploadProgress: 0,
        };
        this.uploadFile = this.uploadFile.bind(this);
        this.updateFiles = this.updateFiles.bind(this);
        this.handleSelectionModelChange = this.handleSelectionModelChange.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleDismissDelete = this.handleDismissDelete.bind(this);
        this.handleConfirmDelete = this.handleConfirmDelete.bind(this);
        this.handleUploadProgress = this.handleUploadProgress.bind(this);
    }

    componentDidMount() {
        this.updateFiles();
    }

    updateFiles() {
        this.setState({isLoading: true, filesSelected: []});
        getFiles()
            .then(files => {
                this.setState({files, isLoading: false});
            })
            .catch(error => {
                console.log(error);
            });
    }

    handleUploadProgress(progressEvent) {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        this.setState({uploadProgress: percentCompleted});
    }

    uploadFile(event) {
        this.setState({
            uploadInProgress: true,
            uploadProgress: 0
        });
        postFile(event.target.files[0], this.handleUploadProgress)
            .then(() => {
                this.setState({uploadProgress: 100});
                setTimeout(() => this.setState({uploadInProgress: false}), 1000);
                this.updateFiles();
            })
            .catch(error => {
                console.log(error);
            })
    }

    handleDelete() {
        const selectedItem = this.state.files.find(x => x.id === parseInt(this.state.filesSelected[0]))
        this.setState({
            confirmDeleteDialogOpen: true,
            deleteItemDescription: selectedItem.filename,
        });
    }

    handleConfirmDelete() {
        const selectedItem = this.state.files.find(x => x.id === parseInt(this.state.filesSelected[0]))
        this.setState({
            confirmDeleteDialogOpen: false,
            deleteItemDescription: '',
        });
        deleteFile(selectedItem.filename)
            .then(() => {
                this.updateFiles();
            })
            .catch((error) => {
                console.log(error);
            });
    }

    handleDismissDelete() {
        this.setState({
            filesSelected: [],
            confirmDeleteDialogOpen: false,
            deleteItemDescription: '',
        });
    }

    handleSelectionModelChange(newSelection) {
        this.setState({
            filesSelected: newSelection.selectionModel
        })
    }

    render() {
        const {classes} = this.props;
        const {
            files,
            isLoading,
            filesSelected,
            confirmDeleteDialogOpen,
            deleteItemDescription,
            uploadInProgress,
            uploadProgress,
        } = this.state;

        return (
            <Grid container spacing={4} className={classes.root}>
                <Grid item xs={12} className={classes.tableContainer}>
                    <Paper className={classes.paperTable}>
                        <DataGrid columns={columns}
                                  rows={files}
                                  pageSize={10}
                                  loading={isLoading}
                                  selectionModel={filesSelected}
                                  onSelectionModelChange={this.handleSelectionModelChange}
                                  disableColumnMenu
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12} container justify="space-between">
                    <Grid item>
                        <Button color="primary"
                                startIcon={<Delete/>}
                                variant="outlined"
                                onClick={this.handleDelete}
                                disabled={!filesSelected || filesSelected.length < 1}>
                            löschen
                        </Button>
                        <ConfirmDeleteDialog
                            open={confirmDeleteDialogOpen}
                            onConfirm={this.handleConfirmDelete}
                            onDismiss={this.handleDismissDelete}
                            itemDescription={deleteItemDescription}
                        />
                    </Grid>
                    <Grid item>
                        <label htmlFor="btn-upload">
                            <input id="btn-upload"
                                   name="btn-upload"
                                   style={{ display: 'none' }}
                                   type="file"
                                   onChange={this.uploadFile}
                                   disabled={uploadInProgress}
                            />
                            <div className={classes.buttonWrapper}>
                                <Button className="btn-choose"
                                        variant="contained"
                                        color="primary"
                                        component="span"
                                        startIcon={<Publish/>}
                                        disabled={uploadInProgress}
                                >
                                    Datei hochladen
                                </Button>
                                {uploadInProgress && <CircularProgress
                                    size={24}
                                    className={classes.buttonProgress}
                                    variant="determinate"
                                    value={uploadProgress}
                                />}
                            </div>
                        </label>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(AdminFiles);
