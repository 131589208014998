import React from 'react';
import {Container, Typography} from "@material-ui/core";

class Datenschutz extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const headingProps = {paragraph: true, variant: "h4"};
        const paragraphProps = {paragraph: true, align: "justify"};

        return (
            <Container maxWidth="md">
                <Typography variant="h3" align="center" paragraph>
                    Datenschutz
                </Typography>

                <Typography {...paragraphProps}>
                    Wir verarbeiten personenbezogene Daten, die beim Besuch unserer Webseite erhoben werden, unter
                    Beachtung der geltenden datenschutzrechtlichen Bestimmungen, insbesondere der
                    EU-Datenschutz-Grundverordnung (DSGVO) und des Landesdatenschutzgesetzes Brandenburg  (BbgDSG).
                </Typography>
                <Typography {...paragraphProps}>
                    Verantwortlicher im Sinne der DSGVO und anderer nationaler Datenschutzgesetze der Mitgliedsstaaten
                    sowie sonstiger datenschutzrechtlicher Bestimmungen ist die:
                </Typography>
                <Typography {...paragraphProps}>
                    Universität Potsdam<br/>
                    vertreten durch den Präsidenten Prof. Oliver Günther, Ph.D.<br/>
                    <br/>
                    <b>Anschrift:</b><br/>
                    Am Neuen Palais 10<br/>
                    14469 Potsdam<br/>
                    Telefon +49 331 977-1220<br/>
                    Telefax +49 331 977-1089<br/>
                    buero.praesident@uni-potsdam.de
                </Typography>

                <Typography {...headingProps}>
                    Zweck und Rechtsgrundlagen
                </Typography>
                <Typography {...paragraphProps}>
                    Der Internetauftritt dient der Information der Öffentlichkeit über die Tätigkeit der Universität
                    Potsdam. Rechtsgrundlage für die mit dem Aufrufen der Seite verbundene Datenverarbeitung ist
                    Art. 6 Abs. 1 S. 1 lit. e DSGVO i. V. m. §§ 3 Abs. 7 BbgHG, 5 Abs. 1 BbgDSG.
                </Typography>
                <Typography {...paragraphProps}>
                    Soweit die Universität für die Erhebung personenbezogener Daten eine Einwilligung
                    (u. a. Kontaktformular) der betroffenen Person einholt, dient Art. 6 Abs. 1 S. 1 lit. a DSGVO als
                    Rechtsgrundlage.
                </Typography>

                <Typography {...headingProps}>
                    Protokollierung
                </Typography>
                <Typography {...paragraphProps}>
                    Bei jedem Zugriff auf unsere Webseite werden zum Zwecke der Betriebs- und Datensicherheit sowie zu
                    statistischen Zwecken folgende Daten in Protokolldateien gespeichert:
                </Typography>
                <Typography component="ul" paragraph>
                    <li>IP-Adresse,</li>
                    <li>Zeitpunkt des Zugriffs,</li>
                    <li>UserAgent (verwendeter Browser und genutztes Betriebssystem),</li>
                    <li>aufgerufene Seiten- bzw. Inhaltselemente und</li>
                    <li>sofern mitgeliefert Referrer URL.</li>
                </Typography>
                <Typography {...paragraphProps}>
                    Die Daten werden maximal 7 Tage gespeichert.
                    Für die Verbesserung des Webauftritts der Universität Potsdam erfolgt eine statistische Auswertung.
                    Dafür werden die oben genannten Daten verarbeitet und durch Kürzen der IP-Adressen anonymisiert und
                    für maximal 12 Monate gespeichert.
                </Typography>

                <Typography {...headingProps}>
                    Cookies
                </Typography>
                <Typography {...paragraphProps}>
                    Beim Besuch unserer Webseite werden sogenannte „Session-Cookies“ auf Ihrem Rechner abgelegt und
                    gespeichert. Cookies sind kleine Textdateien, die dazu dienen, dass Internetangebot
                    nutzerfreundlicher, effektiver und sicherer zu machen. Sie richten auf Ihrem Rechner keinen
                    Schaden an und enthalten keine Viren. In der Regel werden Session-Cookies nach Ende Ihres Besuches
                    automatisch gelöscht. Sie haben die Möglichkeit durch entsprechende Einstellungen in Ihrem Browser
                    das Speichern von Cookies zu deaktivieren. Bitte beachten Sie, dass Sie durch die Deaktivierung
                    gegebenenfalls nicht alle Funktionen unserer Webseite vollumfänglich nutzen können.
                </Typography>

                <Typography {...headingProps}>
                    Suchfunktion
                </Typography>
                <Typography {...paragraphProps}>
                    Auf den Webseiten wird für die Suchfunktion Google verwendet. Dies ist ein Dienst der
                    Google LLC. („Google“). Der Aufruf dieser Ressource erfolgt durch den Webbrowser. Dabei überträgt
                    der Webbrowser Daten an Google. Nähere Informationen finden Sie in den Datenschutzhinweisen
                    von Google (https://policies.google.com).
                </Typography>

                <Typography {...headingProps}>
                    Kontaktformular
                </Typography>
                <Typography {...paragraphProps}>
                    Die von Ihnen per Kontaktformular übermittelten personenbezogenen Daten werden nur zum Zwecke der
                    Beantwortung Ihrer Anfrage gespeichert und nach Wegfall des Verarbeitungsgrunds gelöscht.
                    Ihre Daten werden nicht an Dritte weitergegeben.
                </Typography>

                <Typography {...headingProps}>
                    Social Media
                </Typography>
                <Typography {...paragraphProps}>
                    In die Webseiten der Universität Potsdam wurden Links zu diversen Social Media-Plattformen wie
                    Facebook, Twitter, Instagram u. a. integriert. Auf den zentralen Universität Potsdam-Webseiten
                    handelt es sich dabei jedoch nicht um eine Integration von Daten wie etwa den „Gefällt mir“-Button,
                    sondern ausschließlich um Links auf die jeweilige Außendarstellung der Universität auf der
                    entsprechenden Plattform. Es werden keine datenschutzrelevanten Daten gespeichert.
                </Typography>

                <Typography {...headingProps}>
                    Ihre Rechte
                </Typography>
                <Typography {...paragraphProps}>
                    Sie haben das Recht, eine erteilte Einwilligung in die Erhebung personenbezogener Daten jederzeit
                    und ohne Angaben von Gründen mit Wirkung für die Zukunft schriftlich gegenüber der Universität
                    Potsdam, Am Neuen Palais 10, 14469 Potsdam, oder per E-Mail an presse@uni-potsdam.de zu widerrufen.
                    Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum
                    Widerruf erfolgten Verarbeitung nicht berührt.
                </Typography>
                <Typography {...paragraphProps}>
                    Darüber hinaus haben Sie das Recht, auf Antrag unentgeltlich Auskunft über Ihre gespeicherten
                    personenbezogenen Daten, deren Herkunft, Empfänger, den Zweck und die Dauer der Datenverarbeitung
                    zu erhalten. Zusätzlich haben Sie unter bestimmten Voraussetzungen, das Recht auf Berichtigung, auf
                    Einschränkung der Verarbeitung (z. B. Sperrung), Löschung sowie das Recht auf Datenübertragbarkeit
                    Ihrer Daten. Aus Gründen, die sich aus ihrer besonderen Situation ergeben, können sie jederzeit
                    gegen die Verarbeitung ihrer personenbezogenen Daten, welche aufgrund von
                    Art. 6 Abs. 1 S. 1 lit. e oder f DSGVO erfolgt, Widerspruch einlegen.
                </Typography>
                <Typography {...paragraphProps}>
                    Für die Ausübung Ihrer oben genannten Rechte können Sie sich wenden an:
                </Typography>
                <Typography {...paragraphProps}>
                    Universität Potsdam<br/>
                    Referat für Presse- und Öffentlichkeitsarbeit<br/>
                    Am Neuen Palais 10<br/>
                    14469 Potsdam<br/>
                    Telefon +49 331 977-1474<br/>
                    presse@uni-potsdam.de
                </Typography>
                <Typography {...paragraphProps}>
                    Auskunft können Sie abweichend davon beim Dezernat für Personal- und Rechtsangelegenheiten
                    beantragen. Das Formular für Auskunftsbegehren können Sie über die Webseite des
                    Datenschutzbeauftragten abrufen.
                </Typography>
                <Typography {...paragraphProps}>
                    Bei Fragen zum Datenschutz können Sie sich an den behördlichen Datenschutzbeauftragten der
                    Universität wenden:
                </Typography>
                <Typography {...paragraphProps}>
                    Dr. Marek Kneis<br/>
                    Am Neuen Palais 10<br/>
                    14469 Potsdam, Haus 22 Raum 1.14<br/>
                    Telefon +49 331 977-124409<br/>
                    Telefax +49 331 977-1297<br/>
                    datenschutz@uni-potsdam.de
                </Typography>
                <Typography {...paragraphProps}>
                    Falls Sie der Ansicht sind, dass die Verarbeitung Ihrer personenbezogenen Daten nicht rechtmäßig
                    erfolgt, haben Sie das Recht, bei der zuständigen Aufsichtsbehörde für den Datenschutz eine
                    Beschwerde einzureichen.
                </Typography>

                <Typography {...headingProps}>
                    Widerspruch Werbe-Mails
                </Typography>
                <Typography {...paragraphProps}>
                    Die Nutzung der im Rahmen des Impressums oder vergleichbarer Angaben veröffentlichten Kontaktdaten
                    wie Postanschriften, Telefon- und Faxnummern sowie E-Mail-Adressen durch Dritte zur Übersendung
                    von nicht ausdrücklich angeforderten Informationen ist nicht gestattet. Rechtliche Schritte gegen
                    die Versender von sogenannten Spam-Mails bei Verstößen gegen dieses Verbot sind ausdrücklich
                    vorbehalten.
                </Typography>
            </Container>
        );
    }
}

export default Datenschutz;
