import React from 'react';
import PropTypes from "prop-types";
import {withStyles} from '@material-ui/core/styles';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@material-ui/core";
import clsx from "clsx";

const styles = theme => ({
    root: {},
});

class ConfirmDeleteDialog extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        onConfirm: PropTypes.func.isRequired,
        onDismiss: PropTypes.func.isRequired,
        open: PropTypes.bool.isRequired,
        itemDescription: PropTypes.string,
    }

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
    }

    render() {
        const {style, classes, className, onConfirm, onDismiss, open, itemDescription} = this.props;

        return (
            <Dialog
                open={open}
                onClose={onDismiss}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                style={style}
                className={clsx(classes.root, className)}
            >
                <DialogTitle id="alert-dialog-title">
                    Löschvorgang bestätigen
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Möchten Sie <b>{itemDescription}</b> wirklich löschen?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onDismiss} color="primary" autoFocus>
                        Abbrechen
                    </Button>
                    <Button onClick={onConfirm} color="primary">
                        Löschen
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(ConfirmDeleteDialog);
