import React from 'react';
import axios from "axios";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField
} from "@material-ui/core";
import {Link} from "react-router-dom";

const errorMessages = {
    usernameInvalid: "Benutzername unzulässig.",
    usernameUnknown: "Benutzername unbekannt.",
    passwordInvalid: "Passwort unzulässig.",
    passwordUnknown: "Passwort unbekannt."
}

class LoginDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            usernameError: null,
            password: "",
            passwordError: null,
            readyToSubmit: false
        };
    }

    onUsernameChange(event) {
        const value = event.target.value;
        const readyToSubmit = value.length >= 3 && this.state.password.length >= 3;
        this.setState({
            username: value,
            usernameError: null,
            readyToSubmit: readyToSubmit
        })
    }

    onUsernameBlur(event) {
        const value = event.target.value;
        const valid = value.length >= 3;
        this.setState({
            usernameError: valid ? null : errorMessages.usernameInvalid
        })
    }

    onPasswordChange(event) {
        const value = event.target.value;
        const readyToSubmit = value.length >= 3 && this.state.username.length >= 3;

        this.setState({
            password: value,
            passwordError: null,
            readyToSubmit: readyToSubmit
        })
    }

    onPasswordBlur(event) {
        const value = event.target.value;
        const valid = value.length >= 3;
        this.setState({
            passwordError: valid ? null : errorMessages.passwordInvalid
        })
    }

    handleLogin(event) {
        event.preventDefault();

        if (this.state.readyToSubmit) {
            const data = {
                username: this.state.username,
                password: this.state.password
            }
            axios.post(process.env.REACT_APP_API_URL + '/login', data, {withCredentials: true})
                .then((response) => {
                    this.props.handleLogin(response.data.user);
                })
                .catch((error) => {
                    this.setState({
                        usernameError: errorMessages.usernameUnknown,
                        passwordError: errorMessages.passwordUnknown
                    });
                })
                .then(() => {
                    this.setState({
                        username: "",
                        password: "",
                        readyToSubmit: false
                    });
                });
        }
    }

    render() {
        return (
            <Dialog open={this.props.open}>
                <form onSubmit={this.handleLogin.bind(this)}>
                    <DialogTitle>Benutzeranmeldung</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Bitte geben Sie Ihre Zugangsdaten ein.
                        </DialogContentText>
                        <TextField autoFocus id="username" label="Benutzername" fullWidth margin="dense"
                                   value={this.state.username}
                                   onChange={this.onUsernameChange.bind(this)}
                                   onBlur={this.onUsernameBlur.bind(this)}
                                   error={!!this.state.usernameError}
                                   helperText={this.state.usernameError} />
                        <TextField type="password" id="password" label="Passwort" fullWidth margin="dense"
                                   value={this.state.password}
                                   onChange={this.onPasswordChange.bind(this)}
                                   onBlur={this.onPasswordBlur.bind(this)}
                                   error={!!this.state.passwordError}
                                   helperText={this.state.passwordError} />
                    </DialogContent>
                    <DialogActions>
                        <Button type="submit" color="primary" disabled={!this.state.readyToSubmit}>
                            Anmelden
                        </Button>
                        <Button component={Link} to="/" color="primary">Abbrechen</Button>
                    </DialogActions>
                </form>
            </Dialog>
        )
    }
}

export default LoginDialog;
