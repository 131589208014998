import React from 'react';
import PropTypes from "prop-types";
import {withStyles} from '@material-ui/core/styles';
import {
    getActivities,
    getCrowd,
    getExpertises,
    getLanguages,
    getParticipants,
    getStages,
    getTaskForms,
    postCrowd,
    putCrowd
} from "helpers/api";
import clsx from "clsx";
import {
    Button, Checkbox,
    Container, FormControl, FormControlLabel, FormGroup,
    FormHelperText, FormLabel,
    Grid,
    InputLabel, ListSubheader,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Typography
} from "@material-ui/core";
import {SaveAlt, Undo} from "@material-ui/icons";
import {ROUTE_ADMIN_CROWDS} from "helpers/Constants";
import validator from 'validator';

const styles = theme => ({
    root: {},
    heading: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    formControl: {
        marginTop: theme.spacing(3),
    },
    expertiseFormControlGroup: {
        paddingLeft: theme.spacing(2),
    },
    expertiseFormControl: {
        marginTop: theme.spacing(2),
    },
});

class AdminCrowdsEdit extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        match: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            stageOptions: [],
            activityOptions: [],
            expertiseOptions: [],
            languageOptions: [],
            participantsOptions: [],
            taskFormOptions: [],
            id: undefined,
            name: '', nameErrorMessage: null,
            website: '', websiteErrorMessage: null,
            participants: '', participantsErrorMessage: null,
            taskForms: [], taskFormErrorMessage: null,
            languages: [], languagesErrorMessage: null,
            activities: [], activitiesErrorMessage: null,
            published: true,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleDiscard = this.handleDiscard.bind(this);
    }

    componentDidMount() {
        const id = this.props.match.params.id;
        if(id) {
            this.setState({
                id,
                isLoading: true
            });
            getCrowd(id, true)
                .then((response) => {
                    this.setState({
                        isLoading: false,
                        name: response.name,
                        website: response.website,
                        published: response.published !== 0,
                        participants: response.participants.id,
                        taskForms: response.task_forms.map(x => x.id),
                        languages: response.languages.map(x => x.id),
                        activities: response.activities.map(x => ({
                            activity: x.activity.id,
                            expertises: x.expertises.map(y => y.id),
                        })),
                    });
                })
                .catch(error => console.log(error));
        }
        getStages()
            .then((response) => this.setState({stageOptions: response}))
            .catch(error => console.log(error));
        getActivities()
            .then((response) => this.setState({activityOptions: response}))
            .catch(error => console.log(error));
        getExpertises()
            .then((response) => this.setState({expertiseOptions: response}))
            .catch(error => console.log(error));
        getLanguages()
            .then((response) => this.setState({languageOptions: response}))
            .catch(error => console.log(error));
        getParticipants()
            .then((response) => this.setState({participantsOptions: response}))
            .catch(error => console.log(error));
        getTaskForms()
            .then((response) => this.setState({taskFormOptions: response}))
            .catch(error => console.log(error));
    }

    validate(name, value) {
        let errorMessage = null;

        // noinspection FallThroughInSwitchStatementJS
        switch (name) {
            case 'expertises':
            case 'published':
                break;
            case 'activities':
            case 'taskForms':
            case 'languages':
                if(value.length < 1) errorMessage = 'Pflichtfeld';
                break;
            case 'participants':
                if(value !== parseInt(value)) errorMessage = 'Pflichtfeld';
                break;
            case 'website':
                if(value.length > 0 && !validator.isLength(value, {min: 10, max: 100}))
                    errorMessage = 'Mindestens 10 bis maximal 100 Zeichen.';
                else if (value.length > 0 && !validator.isURL(value, {require_protocol: true}))
                    errorMessage = 'Beispiel: https://www.google.com';
                break;
            default:
                if(!validator.isLength(value, {min: 1}))
                    errorMessage = 'Pflichtfeld';
                else if(!validator.isLength(value, {min: 3}))
                    errorMessage = 'Mindestlänge 3 Zeichen';
        }
        this.setState({[name+'ErrorMessage']: errorMessage});
        return errorMessage === null;
    }

    handleChange(event, field, ...args) {
        let newValue, selectedValue;
        switch(field) {
            case 'published':
                newValue = event.target.checked;
                break;
            case 'expertises':
                newValue = this.state.activities;
                newValue.find(x => x.activity === args[0]).expertises = event.target.value;
                break;
            case 'activities':
                newValue = this.state.activities;
                selectedValue = event.target.value;
                if(selectedValue.includes(undefined)) break; // ignore <ListSubheader> click events
                const addItems = selectedValue.filter(x => !newValue.some(y => y.activity === x));
                if(addItems.length > 0) {
                    newValue.push({activity: addItems[0], expertises: []});
                } else {
                    const removeItems = newValue.filter(x => !selectedValue.some(y => y === x.activity));
                    const index = newValue.findIndex(x => x.activity === removeItems[0]);
                    newValue.splice(index, 1);
                }
                break;
            default:
                newValue = event.target.value;
        }

        this.setState({
            [field]: newValue,
            [field + 'ErrorMessage']: null
        });
    }

    handleBlur(event, field) {
        this.validate(field, this.state[field]);
    }

    handleSave() {
        const {id, name, website, participants, taskForms, languages, activities, published} = this.state;
        const {participantsOptions, languageOptions, taskFormOptions, activityOptions, expertiseOptions} = this.state;

        let valid = true;
        valid &= this.validate('name', name);
        valid &= this.validate('website', website);
        valid &= this.validate('participants', participants);
        valid &= this.validate('taskForms', taskForms);
        valid &= this.validate('languages', languages);
        valid &= this.validate('activities', activities);
        if(!valid) return;

        const data = {
            name,
            website: website !== '' ? website : null,
            published: published ? 1 : 0,
            participants: participantsOptions.find(x => x.id === participants),
            languages: languages.map(x => languageOptions.find(y => y.id === x)),
            task_forms: taskForms.map(x => taskFormOptions.find(y => y.id === x)),
            activities: activities.map(x => ({
                activity: activityOptions.find(y => y.id === x.activity),
                expertises: x.expertises.map(y => expertiseOptions.find(z => z.id === y)),
            }))
        };

        console.log(data);

        if (id) {
            putCrowd(id, data)
                .then(() => {
                    this.props.history.push(ROUTE_ADMIN_CROWDS);
                })
                .catch((error) => {
                    console.log(error);
                })
        } else {
            postCrowd(data)
                .then(() => {
                    this.props.history.push(ROUTE_ADMIN_CROWDS);
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }

    handleDiscard() {
        this.props.history.push(ROUTE_ADMIN_CROWDS);
    }

    render() {
        const {classes, style, className} = this.props;
        const {
            id,
            participantsOptions,
            languageOptions,
            taskFormOptions,
            activityOptions,
            stageOptions,
            expertiseOptions,
            name, nameErrorMessage,
            website, websiteErrorMessage,
            participants, participantsErrorMessage,
            languages, languagesErrorMessage,
            taskForms, taskFormsErrorMessage,
            activities, activitiesErrorMessage,
            published
        } = this.state;

        const createChangeHandler = (field) => (event) => this.handleChange(event, field);
        const createExpertisesChangeHandler = (field, activity) => (event) => this.handleChange(event, field, activity);
        const createBlurHandler = (field) => (event) => this.handleBlur(event, field);

        const formControlProps = {fullWidth: true, variant: 'outlined', className: classes.formControl}

        const optionsLoaded = activityOptions.length > 0 && stageOptions.length > 0
            && expertiseOptions.length > 0 && languageOptions.length > 0
            && taskFormOptions.length > 0 && participantsOptions.length > 0;

        return (
            <Container maxWidth="sm" style={style} className={clsx(classes.root, className)}>
                <Typography variant="h3" className={classes.heading}>
                    Crowd {id ? "bearbeiten" : "erstellen"}
                </Typography>
                <form id="crowd-form">
                    <TextField id="name" label="Name" {...formControlProps} required
                               value={name} onChange={createChangeHandler('name')}
                               onBlur={createBlurHandler('name')}
                               error={!!nameErrorMessage} helperText={nameErrorMessage}/>
                    <TextField id="website" label="Website" {...formControlProps}
                               value={website} onChange={createChangeHandler('website')}
                               onBlur={createBlurHandler('website')}
                               error={!!websiteErrorMessage} helperText={websiteErrorMessage}/>
                    <FormControl required error={!!participantsErrorMessage} {...formControlProps}>
                        <InputLabel id="participants-label">Teilnehmer</InputLabel>
                        <Select
                            labelId="participants-label"
                            id="participants"
                            value={optionsLoaded && participants !== "" ? participants : ""}
                            onChange={createChangeHandler('participants')}
                            onBlur={createBlurHandler('participants')}
                            input={<OutlinedInput label="Teilnehmer"/>}
                        >
                            {optionsLoaded && participantsOptions.map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.description}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{participantsErrorMessage}</FormHelperText>
                    </FormControl>
                    <FormControl required error={!!languagesErrorMessage} {...formControlProps}>
                        <InputLabel id="languages-label">Sprachen</InputLabel>
                        <Select
                            labelId="languages-label"
                            id="languages"
                            value={optionsLoaded ? languages : []}
                            onChange={createChangeHandler('languages')}
                            onBlur={createBlurHandler('languages')}
                            input={<OutlinedInput label="Sprachen"/>}
                            multiple
                        >
                            {optionsLoaded && languageOptions.map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.language}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{languagesErrorMessage}</FormHelperText>
                    </FormControl>
                    <FormControl required error={!!taskFormsErrorMessage} {...formControlProps}>
                        <InputLabel id="task-forms-label">Aufgabenformen</InputLabel>
                        <Select
                            labelId="task-forms-label"
                            id="task-forms"
                            value={optionsLoaded ? taskForms : []}
                            onChange={createChangeHandler('taskForms')}
                            onBlur={createBlurHandler('taskForms')}
                            input={<OutlinedInput label="Aufgabenformen"/>}
                            multiple
                        >
                            {optionsLoaded && taskFormOptions.map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.description}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{taskFormsErrorMessage}</FormHelperText>
                    </FormControl>
                    <FormControl required error={!!activitiesErrorMessage} {...formControlProps}>
                        <InputLabel id="activities-label">Stufen & Fragen</InputLabel>
                        <Select
                            labelId="activities-label"
                            id="activities"
                            value={optionsLoaded ? activities.map(x => x.activity) : []}
                            onChange={createChangeHandler('activities')}
                            onBlur={createBlurHandler('activities')}
                            input={<OutlinedInput label="Stufen & Fragen"/>}
                            multiple
                        >
                            {optionsLoaded && stageOptions.map((stage) => [
                                <ListSubheader>{stage.description}</ListSubheader>,
                                activityOptions.filter(x => x.stage_id === stage.id).map((activity) => (
                                    <MenuItem key={activity.id} value={activity.id}>
                                        {activity.description}
                                    </MenuItem>
                                ))
                            ])}
                        </Select>
                        <FormHelperText>{activitiesErrorMessage}</FormHelperText>
                    </FormControl>
                    <FormControl component="fieldset" {...formControlProps}>
                        <FormLabel component="legend">Community-Expertise</FormLabel>
                        <FormGroup className={classes.expertiseFormControlGroup}>
                            {optionsLoaded && (activities.length > 0) ? activities
                                .map(a => ({
                                    activity: activityOptions.find(x => x.id === a.activity),
                                    expertises: a.expertises
                                }))
                                .map(a => (
                                    <FormControl variant="outlined" fullWidth key={a.activity.id}
                                                 className={classes.expertiseFormControl}>
                                        <InputLabel id={"expertises-" + a.activity.id + "-label"} shrink>
                                            {stageOptions.find(x => x.id === a.activity.stage_id).description}
                                            : {a.activity.description}
                                        </InputLabel>
                                        <Select
                                            labelId={"expertises-" + a.activity.id + "-label"}
                                            id={"expertises-" + a.activity.id}
                                            value={a.expertises}
                                            onChange={createExpertisesChangeHandler('expertises', a.activity.id)}
                                            onBlur={createBlurHandler('expertises')}
                                            displayEmpty
                                            input={
                                                <OutlinedInput
                                                    notched
                                                    label={stageOptions
                                                        .find(x => x.id === a.activity.stage_id).description +
                                                        ": " + a.activity.description}/>
                                            }
                                            renderValue={(selection) => {
                                                if(selection.length === 0) {
                                                    return <em>Keine Angabe</em>;
                                                }
                                                return selection
                                                    .map(x => expertiseOptions.find(y => y.id === x).description)
                                                    .join(', ');
                                            }}
                                            multiple
                                        >
                                            <MenuItem disabled value="">
                                                <em>Keine Angabe</em>
                                            </MenuItem>
                                            {expertiseOptions.map((item) => (
                                                <MenuItem key={item.id} value={item.id}>
                                                    {item.description}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )) : (
                                    <Typography className={classes.formControl} align={"center"}>
                                        <em>Keine Stufe & Frage ausgewählt.</em>
                                    </Typography>
                                )}
                        </FormGroup>
                    </FormControl>
                    <FormControl component="fieldset" {...formControlProps}>
                        <FormLabel component="legend">Einstellungen</FormLabel>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox checked={published}
                                                   onChange={createChangeHandler('published')}
                                                   onBlur={createBlurHandler('published')}
                                                   color="primary" />}
                                label="Veröffentlichen"
                                labelPlacement="end"/>
                        </FormGroup>
                    </FormControl>
                    <Grid container justify={"space-between"} className={classes.formControl}>
                        <Button variant="contained"
                                color="primary"
                                onClick={this.handleSave}
                                startIcon={<SaveAlt/>}
                        >
                            Speichern
                        </Button>
                        <Button variant="outlined"
                                color="primary"
                                startIcon={<Undo/>}
                                onClick={this.handleDiscard}>
                            Verwerfen
                        </Button>
                    </Grid>
                </form>
            </Container>
        );
    }
}

export default withStyles(styles)(AdminCrowdsEdit);
