import React from 'react';
import PropTypes from "prop-types";
import {withStyles} from '@material-ui/core/styles';
import clsx from "clsx";
import {
    Button,
    Checkbox,
    Container,
    FormControl,
    FormControlLabel,
    FormGroup, FormHelperText,
    FormLabel,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Typography
} from "@material-ui/core";
import {
    getActivities,
    getBestPractice,
    getCrowds,
    getFiles,
    getStages,
    postBestPractice,
    putBestPractice
} from "helpers/api";
import {SaveAlt, Undo} from "@material-ui/icons";
import {ROUTE_ADMIN_BEST_PRACTICES} from "helpers/Constants";

const styles = theme => ({
    root: {},
    heading: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
});

class AdminBestPracticesEdit extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        match: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            files: [],
            stages: [],
            activities: [],
            crowds: [],
            id: undefined,
            title: '', titleErrorMessage: null,
            crowd: '', crowdErrorMessage: null,
            crowdLogo: '',
            clientName: '', clientNameErrorMessage: null,
            clientLogo: '',
            goal: '', goalErrorMessage: null,
            task: '', taskErrorMessage: null,
            taskForm: '', taskFormErrorMessage: null,
            results: '', resultsErrorMessage: null,
            numbers: '', numbersErrorMessage: null,
            compensation: '', compensationErrorMessage: null,
            pdfDocument: '',
            activity: '', activityErrorMessage: null,
            frontPage: false,
            published: true,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleDiscard = this.handleDiscard.bind(this);
    }

    componentDidMount() {
        const id = this.props.match.params.id;
        if(id) {
            this.setState({
                id,
                isLoading: true
            });
            getBestPractice(id, true)
                .then((response) => {
                    this.setState({
                        isLoading: false,
                        title: response.title,
                        crowd: response.crowd.id,
                        crowdLogo: response.crowd_logo || '',
                        clientName: response.client_name,
                        clientLogo: response.client_logo || '',
                        goal: response.goal,
                        task: response.task,
                        taskForm: response.task_form,
                        results: response.results,
                        numbers: response.numbers || '',
                        compensation: response.compensation || '',
                        pdfDocument: response.pdf_document || '',
                        activity: response.activity.id,
                        frontPage: response.front_page !== 0,
                        published: response.published !== 0,
                    })
                });
        }
        getFiles()
            .then((response) => this.setState({files: response}))
            .catch(error => console.log(error));
        getStages()
            .then((response) => this.setState({stages: response}))
            .catch(error => console.log(error));
        getActivities()
            .then((response) => this.setState({activities: response}))
            .catch(error => console.log(error));
        getCrowds(true)
            .then((response) => this.setState({crowds: response}))
            .catch(error => console.log(error));
    }

    validate(name, value) {
        let errorMessage = null;

        // noinspection FallThroughInSwitchStatementJS
        switch (name) {
            case 'crowdLogo':
            case 'clientLogo':
            case 'pdfDocument':
            case 'frontPage':
            case 'published':
                break;
            case 'activity':
            case 'crowd':
                if(value !== parseInt(value)) errorMessage = 'Pflichtfeld';
                break;
            case 'numbers':
            case 'compensation':
                if(value.length > 0 && value.length < 3) errorMessage = 'Mindestlänge 3 Zeichen';
                break;
            default:
                if(value.length === 0) errorMessage = 'Pflichtfeld';
                else if(value.length < 3) errorMessage = 'Mindestlänge 3 Zeichen';
        }
        this.setState({[name+'ErrorMessage']: errorMessage});
        return errorMessage === null;
    }

    handleChange(event, field) {
        let newValue;
        switch(field) {
            case 'published':
            case 'frontPage':
                newValue = event.target.checked;
                break;
            default:
                newValue = event.target.value;
        }

        this.setState({
            [field]: newValue,
            [field + 'ErrorMessage']: null
        });
    }

    handleBlur(event, field) {
        this.validate(field, this.state[field]);
    }

    handleSave() {
        const {
            id, title, crowd, crowdLogo, clientName, clientLogo, goal, task, compensation,
            taskForm, results, numbers, pdfDocument, activity, frontPage, published
        } = this.state;
        const {activities, crowds} = this.state;

        let valid = true;
        valid &= this.validate('title', title);
        valid &= this.validate('crowd', crowd);
        valid &= this.validate('clientName', clientName);
        valid &= this.validate('clientLogo', clientLogo);
        valid &= this.validate('goal', goal);
        valid &= this.validate('task', task);
        valid &= this.validate('taskForm', taskForm);
        valid &= this.validate('results', results);
        valid &= this.validate('numbers', numbers);
        valid &= this.validate('compensation', compensation);
        valid &= this.validate('activity', activity);
        if(!valid) return;

        const data = {
            title,
            crowd: crowds.find(x => x.id === crowd),
            crowd_logo: crowdLogo === '' ? null : crowdLogo,
            client_name: clientName,
            client_logo: clientLogo === '' ? null : clientLogo,
            goal,
            task,
            task_form: taskForm,
            results,
            numbers: numbers === '' ? null : numbers,
            compensation: compensation === '' ? null : compensation,
            pdf_document: pdfDocument === '' ? null : pdfDocument,
            activity: activities.find(x => x.id === activity),
            front_page: frontPage ? 1 : 0,
            published: published ? 1 : 0,
        }

        if (id) {
            putBestPractice(id, data)
                .then(() => {
                    this.props.history.push(ROUTE_ADMIN_BEST_PRACTICES);
                })
                .catch((error) => {
                    console.log(error);
                })
        } else {
            postBestPractice(data)
                .then(() => {
                    this.props.history.push(ROUTE_ADMIN_BEST_PRACTICES);
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }

    handleDiscard() {
        this.props.history.push(ROUTE_ADMIN_BEST_PRACTICES);
    }

    render() {
        const {classes, style, className} = this.props;
        const {
            files,
            activities,
            stages,
            crowds,
            id,
            title, titleErrorMessage,
            crowd, crowdErrorMessage,
            crowdLogo,
            clientName, clientNameErrorMessage,
            clientLogo,
            goal, goalErrorMessage,
            task, taskErrorMessage,
            taskForm, taskFormErrorMessage,
            results, resultsErrorMessage,
            numbers, numbersErrorMessage,
            compensation, compensationErrorMessage,
            pdfDocument,
            activity, activityErrorMessage,
            frontPage,
            published
        } = this.state;

        const createChangeHandler = (field) => (event) => this.handleChange(event, field);
        const createBlurHandler = (field) => (event) => this.handleBlur(event, field);

        const gridItemProps = {item: true, xs: 12}
        const formControlProps = {fullWidth: true, variant: 'outlined'}

        return (
            <Container maxWidth="sm" style={style} className={clsx(classes.root, className)}>
                <Typography variant="h3" className={classes.heading}>
                    Praxisbeispiel {id ? "bearbeiten" : "erstellen"}
                </Typography>
                <form id="contact-form">
                    <Grid container spacing={3} justify="center">
                        <Grid {...gridItemProps}>
                            <TextField id="title" label="Titel" {...formControlProps} required
                                       value={title} onChange={createChangeHandler('title')}
                                       onBlur={createBlurHandler('title')}
                                       error={!!titleErrorMessage} helperText={titleErrorMessage}/>
                        </Grid>
                        <Grid {...gridItemProps}>
                            <FormControl variant="outlined" fullWidth required error={!!activityErrorMessage}>
                                <InputLabel id="activity-label">Stufe & Frage</InputLabel>
                                <Select
                                    labelId="activity-label"
                                    id="activity"
                                    value={activities.length > 0 && activity !== "" ? activity : ""}
                                    onChange={createChangeHandler('activity')}
                                    onBlur={createBlurHandler('activity')}
                                    input={<OutlinedInput label="Stufe & Frage"/>}
                                >
                                    {activities.length > 0 && stages.length > 0 && activities.map((item) => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {stages.find(x => x.id === item.stage_id).description}: {item.question}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>{activityErrorMessage}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid {...gridItemProps}>
                            <FormControl variant="outlined" fullWidth required error={!!crowdErrorMessage}>
                                <InputLabel id="crowd-label">Crowdsourcing-Plattform / Anbieter</InputLabel>
                                <Select
                                    labelId="crowd-label"
                                    id="crowd"
                                    value={crowds.length > 0 && crowd !== "" ? crowd : ""}
                                    onChange={createChangeHandler('crowd')}
                                    onBlur={createBlurHandler('crowd')}
                                    input={<OutlinedInput label="Crowdsourcing-Plattform / Anbieter"/>}
                                >
                                    {crowds.length > 0 && crowds.map((item) => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>{activityErrorMessage}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid {...gridItemProps}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel id="crowd-logo-label" shrink>
                                    Logo Crowdsourcing-Plattform / Anbieter
                                </InputLabel>
                                <Select
                                    labelId="crowd-logo-label"
                                    id="crowd-logo"
                                    value={files.length > 0 ? crowdLogo : ""}
                                    onChange={createChangeHandler('crowdLogo')}
                                    onBlur={createBlurHandler('crowdLogo')}
                                    displayEmpty
                                    input={<OutlinedInput notched label="Logo Crowdsourcing-Plattform / Anbieter"/>}
                                >
                                    <MenuItem value="">
                                        <em>Kein Logo anzeigen</em>
                                    </MenuItem>
                                    {files.map((item) => (
                                        <MenuItem key={item.id} value={item.filename}>{item.filename}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid {...gridItemProps}>
                            <TextField id="client-name" label="Unternehmen / Kunde" {...formControlProps} required
                                       value={clientName}
                                       onChange={createChangeHandler('clientName')}
                                       onBlur={createBlurHandler('clientName')}
                                       error={!!clientNameErrorMessage} helperText={clientNameErrorMessage}/>
                        </Grid>
                        <Grid {...gridItemProps}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel id="client-logo-label" shrink>Logo Unternehmen / Kunde</InputLabel>
                                <Select
                                    labelId="client-logo-label"
                                    id="client-logo"
                                    value={files.length > 0 ? clientLogo : ""}
                                    onChange={createChangeHandler('clientLogo')}
                                    onBlur={createBlurHandler('clientLogo')}
                                    displayEmpty
                                    input={<OutlinedInput notched label="Logo Unternehmen / Kunde"/>}
                                >
                                    <MenuItem value="">
                                        <em>Kein Logo anzeigen</em>
                                    </MenuItem>
                                    {files.map((item) => (
                                        <MenuItem key={item.id} value={item.filename}>{item.filename}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid {...gridItemProps}>
                            <TextField id="goal" label="Ziel" {...formControlProps} required
                                       value={goal} onChange={createChangeHandler('goal')}
                                       onBlur={createBlurHandler('goal')} multiline rows={4}
                                       error={!!goalErrorMessage} helperText={goalErrorMessage}/>
                        </Grid>
                        <Grid {...gridItemProps}>
                            <TextField id="task" label="Konkrete Aufgabenstellung" {...formControlProps} required
                                       value={task} onChange={createChangeHandler('task')} multiline rows={4}
                                       onBlur={createBlurHandler('task')}
                                       error={!!taskErrorMessage} helperText={taskErrorMessage}/>
                        </Grid>
                        <Grid {...gridItemProps}>
                            <TextField id="task-form" label="Art des durchgeführten Crowdsourcings" {...formControlProps} required multiline
                                       value={taskForm} onChange={createChangeHandler('taskForm')} rows={4}
                                       onBlur={createBlurHandler('taskForm')}
                                       error={!!taskFormErrorMessage} helperText={taskFormErrorMessage}/>
                        </Grid>
                        <Grid {...gridItemProps}>
                            <TextField id="results" label="Ergebnis des Crowdsourcings" {...formControlProps} required multiline
                                       value={results} onChange={createChangeHandler('results')} rows={4}
                                       onBlur={createBlurHandler('results')}
                                       error={!!resultsErrorMessage} helperText={resultsErrorMessage}/>
                        </Grid>
                        <Grid {...gridItemProps}>
                            <TextField id="numbers" label="Einreichungen / Teilnahmezahlen / Partizipation" {...formControlProps} multiline
                                       value={numbers} onChange={createChangeHandler('numbers')} rows={4}
                                       onBlur={createBlurHandler('numbers')}
                                       error={!!numbersErrorMessage} helperText={numbersErrorMessage}/>
                        </Grid>
                        <Grid {...gridItemProps}>
                            <TextField id="compensation" label="Kompensation" {...formControlProps} multiline
                                       value={compensation} onChange={createChangeHandler('compensation')} rows={4}
                                       onBlur={createBlurHandler('compensation')}
                                       error={!!compensationErrorMessage} helperText={compensationErrorMessage}/>
                        </Grid>
                        <Grid {...gridItemProps}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel id="pdf-document-label" shrink>PDF Dokument</InputLabel>
                                <Select
                                    labelId="pdf-document-label"
                                    id="pdf-document"
                                    value={files.length > 0 ? pdfDocument : ""}
                                    onChange={createChangeHandler('pdfDocument')}
                                    onBlur={createBlurHandler('pdfDocument')}
                                    displayEmpty
                                    input={<OutlinedInput notched label="PDF Dokument"/>}
                                >
                                    <MenuItem value="">
                                        <em>Kein PDF Dokument</em>
                                    </MenuItem>
                                    {files.map((item) => (
                                        <MenuItem key={item.id} value={item.filename}>{item.filename}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid {...gridItemProps}>
                            <FormControl component="fieldset" variant="outlined">
                                <FormLabel component="legend">Einstellungen</FormLabel>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox checked={published}
                                                           onChange={createChangeHandler('published')}
                                                           onBlur={createBlurHandler('published')}
                                                           color="primary" />}
                                        label="Veröffentlichen"
                                        labelPlacement="end"/>
                                    <FormControlLabel
                                        control={<Checkbox checked={frontPage}
                                                           onChange={createChangeHandler('frontPage')}
                                                           onBlur={createBlurHandler('frontPage')}
                                                           color="primary" />}
                                        label="Auf der Startseite anzeigen"
                                        labelPlacement="end"/>
                                </FormGroup>
                            </FormControl>
                        </Grid>
                        <Grid {...gridItemProps} container justify={"space-between"}>
                            <Button variant="contained"
                                    color="primary"
                                    startIcon={<SaveAlt/>}
                                    onClick={this.handleSave}>
                                Speichern
                            </Button>
                            <Button variant="outlined"
                                    color="primary"
                                    startIcon={<Undo/>}
                                    onClick={this.handleDiscard}>
                                Verwerfen
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Container>
        );
    }
}

export default withStyles(styles)(AdminBestPracticesEdit);
