import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {Grid, Paper, Typography} from "@material-ui/core";
import {Link} from "react-router-dom";
import {ArrowForwardIos} from "@material-ui/icons";

import {ROUTE_BEST_PRACTICE, ROUTE_CROWD_USERS, ROUTE_HELP} from "helpers/Constants";
import SectionHeading from "components/SectionHeading";
import BestPracticeDetails from "components/BestPracticeDetails";
import ExplainerVideo from "components/ExplainerVideo";
import {getBestPractices} from "helpers/api";
import FullscreenProgress from "components/FullscreenProgress";
import NetworkErrorDialog from "components/NetworkErrorDialog";

const styles = theme => ({
    root: {},
    videoContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    videoPlayer: {
        marginBottom: theme.spacing(4),
        width: '90%',
    },
    bestPracticePaper: {
        padding: theme.spacing(2, 4, 2),
    },
    bestPracticeGrid: {
        marginTop: theme.spacing(2),
    },
    paragraphLearnMore: {
        marginTop: theme.spacing(4),
    },
});

class Start extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);
        this.state = {
            bestPractices: [],
            isLoading: true,
            networkError: false,
        };
    }

    componentDidMount() {
        getBestPractices()
            .then((bestPractices) => {
                this.setState({
                    isLoading: false,
                    bestPractices: bestPractices.filter(x => x.front_page)
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    isLoading: false,
                    networkError: true,
                });
            });
    }

    render() {
        const {classes} = this.props;
        const {bestPractices, isLoading, networkError} = this.state;

        return (
            <React.Fragment>
                <FullscreenProgress open={isLoading}/>
                <NetworkErrorDialog open={networkError}/>

                <SectionHeading>Willkommen bei Ihrem Crowd-Finder</SectionHeading>

                <Grid container spacing={4}>
                    <Grid item md={6} xs={12}>
                        <Typography variant="h6" paragraph>
                            Nutzen Sie jetzt die Crowd als Innovationspartner.
                        </Typography>
                        <Typography align="justify" paragraph>
                            Der Crowd-Finder hilft Ihnen ein diverses Set an Individuen (engl. Crowd) zu finden,
                            welche Sie im Produktentwicklungsprozess unterstützen.
                        </Typography>
                        <Typography align="justify" paragraph>
                            Erhöhen Sie Ihre Agilität und Wettbewerbsfähigkeit durch das Auslagern verschiedener
                            Aufgaben.
                        </Typography>
                        <Typography align="justify" paragraph>
                            Finden Sie unter der großen Anzahl von Crowdsourcing-Plattformen, genau den richten Anbieter
                            für Ihre individuellen und kontextspezifischen Herausforderungen.
                        </Typography>

                        <Typography align="left" paragraph>
                            <Button component={Link} to={ROUTE_HELP} variant="outlined" color="primary"
                                    startIcon={<ArrowForwardIos/>}>
                                Lernen Sie mehr
                            </Button>
                        </Typography>
                    </Grid>
                    <Grid item md={6} xs={12} className={classes.videoContainer}>
                        <ExplainerVideo className={classes.videoPlayer}/>
                    </Grid>
                </Grid>

                <Typography align="center">
                    <Button component={Link} to={ROUTE_CROWD_USERS} variant="contained" color="primary"
                            startIcon={<ArrowForwardIos/>}>
                        Crowdsourcing starten
                    </Button>
                </Typography>

                <SectionHeading>
                    Noch nicht überzeugt? Hier sind Einblicke in erfolgreiche Praxisbeispiele...
                </SectionHeading>

                <Grid container spacing={6} className={classes.bestPracticeGrid}>
                    {bestPractices.map((item) =>
                        <Grid item xs={12} md={6} key={item.id}>
                            <Paper className={classes.bestPracticePaper} square variant="outlined">
                                <BestPracticeDetails data={item} hideNumbers/>
                            </Paper>
                        </Grid>
                    )}
                </Grid>

                <Typography align="left" paragraph className={classes.paragraphLearnMore}>
                    <Button component={Link} to={ROUTE_BEST_PRACTICE} variant="outlined" color="primary"
                            startIcon={<ArrowForwardIos/>}>
                        Erfahren Sie mehr
                    </Button>
                </Typography>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(Start);
