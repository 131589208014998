import React from 'react';
import {Container, Typography} from "@material-ui/core";

class Impressum extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const headingProps = {paragraph: true, variant: "h4"};
        const subHeadingProps = {paragraph: true, variant: "h5"};
        const paragraphProps = {paragraph: true, align: "justify"};

        return (
            <Container maxWidth="md">
                <Typography variant="h3" align="center" paragraph>
                    Impressum
                </Typography>

                <Typography {...headingProps}>
                    Herausgeber
                </Typography>
                <Typography {...paragraphProps}>
                    Universität Potsdam<br/>
                    Am Neuen Palais 10<br/>
                    14469 Potsdam<br/>
                    <br/>
                    Telefon: 0331 977-0<br/>
                    Fax: 0331 97 21 63<br/>
                    E-Mail: buero.praesident@uni-potsdam.de<br/>
                </Typography>

                <Typography {...headingProps}>
                    Rechtsform und gesetzliche Vertretung
                </Typography>
                <Typography {...paragraphProps}>
                    Die Universität Potsdam ist eine Körperschaft des Öffentlichen Rechts.
                    Sie wird gesetzlich vertreten durch Prof. Oliver Günther, Ph.D., Präsident der Universität Potsdam,
                    Am Neuen Palais 10, 14469 Potsdam.
                </Typography>

                <Typography  {...headingProps}>
                    Zuständige Aufsichtsbehörde
                </Typography>
                <Typography {...paragraphProps}>
                    Ministerium für Wissenschaft, Forschung und Kultur des Landes Brandenburg<br/>
                    Dortustr. 36<br/>
                    14467 Potsdam<br/>
                    <br/>
                    Telefon: 0331 866 49 99<br/>
                    E-Mail: presse@mwfk.brandenburg.de<br/>
                </Typography>

                <Typography  {...headingProps}>
                    Umsatzsteueridentifikationsnummer
                </Typography>
                <Typography {...paragraphProps}>
                    DE138408327
                </Typography>

                <Typography  {...headingProps}>
                    Inhaltliche Verantwortlichkeit i. S. v. § 5 TMG und § 55 Abs. 2 RStV
                </Typography>
                <Typography {...paragraphProps}>
                    Die Webseiten der Wirtschaftsinformatik werden von der Professur für Wirtschaftsinformatik,
                    insbesondere. Prozesse und Systeme verantwortet:
                </Typography>
                <Typography {...paragraphProps}>
                    Lehrstuhl für Wirtschaftsinformatik, insb. Prozesse und Systeme<br/>
                    Christof Thim<br/>
                    August-Bebel-Str. 89<br/>
                    14482 Potsdam<br/>
                    <br/>
                    Telefon: 0331 977-3423<br/>
                    Fax: 0331 977-3406<br/>
                    <br/>
                    E-Mail: webmaster@wi.uni-potsdam.de
                </Typography>
                <Typography {...paragraphProps}>
                    Die einzelnen Fakultäten, Institute, Einrichtungen und Verwaltungsstellen der Universität Potsdam
                    sind für die Inhalte und Aktualität ihrer Webseiten zuständig. Die Autorinnen und Autoren der
                    Webseiten sind für die jeweiligen Inhalte verantwortlich.
                </Typography>

                <Typography {...headingProps}>
                    Quellenangaben für die verwendeten Medien
                </Typography>
                <Typography {...paragraphProps}>
                    Soweit nicht anders benannt stammen alle Bilder und Grafiken von Karla Fritze (ZIM) und
                    Thomas Roese (ZIM).
                </Typography>

                <Typography {...headingProps}>
                    Haftungsausschluss
                </Typography>
                <Typography {...subHeadingProps}>
                    Haftung für Inhalte
                </Typography>
                <Typography {...paragraphProps}>
                    Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt.
                    Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine
                    Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf
                    diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als
                    Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen
                    zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
                    Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen
                    Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt
                    der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
                    Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
                </Typography>
                <Typography {...subHeadingProps}>
                    Haftung für Links
                </Typography>
                <Typography {...paragraphProps}>
                    Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss
                    haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte
                    der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich.
                    Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
                    Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente
                    inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer
                    Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige
                    Links umgehend entfernen.
                </Typography>
                <Typography {...subHeadingProps}>
                    Urheberrecht
                </Typography>
                <Typography {...paragraphProps}>
                    Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem
                    deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung
                    außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors
                    bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen
                    Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden
                    die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet.
                    Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
                    entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte
                    umgehend entfernen.
                </Typography>
            </Container>
        );
    }
}

export default Impressum;
