import React from 'react';
import PropTypes from "prop-types";
import {withStyles} from '@material-ui/core/styles';
import {Button, Grid, Paper, Tooltip} from "@material-ui/core";
import {DataGrid} from "@material-ui/data-grid";
import {deleteCrowd, getCrowds} from "helpers/api";
import {Add, CheckBoxOutlineBlankOutlined, CheckBoxOutlined, Delete, Edit, Public} from "@material-ui/icons";
import {ROUTE_ADMIN_CROWDS_EDIT} from "helpers/Constants";
import ConfirmDeleteDialog from "components/ConfirmDeleteDialog";

const styles = theme => ({
    root: {},
    buttonCreate: {
        marginTop: theme.spacing(2),
    },
    buttonContainer: {
        textAlign: 'center',
    },
    tableContainer: {
        marginTop: theme.spacing(4),
    },
    buttonDelete: {
        marginLeft: theme.spacing(2),
    },
    paperTable: {
        height: 650,
    }
});

const columns = [
    {field: 'id', headerName: 'ID', width: 70,},
    {field: 'name', headerName: 'Name', width: 200,},
    {field: 'website', headerName: 'Website', flex: 1,},
    {
        field: 'published',
        description: 'Veröffentlicht',
        width: 90,
        renderHeader: () => <Tooltip title={"Veröffentlicht"} arrow><Public/></Tooltip>,
        renderCell: (params) => (
            params.value ?
                <Tooltip title={"öffentlich"} arrow><CheckBoxOutlined/></Tooltip> :
                <Tooltip title={"nicht öffentlich"} arrow><CheckBoxOutlineBlankOutlined/></Tooltip>
        )
    },
];

class AdminCrowds extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);
        this.state = {
            crowds: [],
            itemsSelected: undefined,
            isLoading: true,
            confirmDeleteDialogOpen: false,
            deleteItemDescription: '',
        };
        this.handleCreate = this.handleCreate.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleConfirmDelete = this.handleConfirmDelete.bind(this);
        this.handleDismissDelete = this.handleDismissDelete.bind(this);
        this.handleSelectionModelChange = this.handleSelectionModelChange.bind(this);
        this.updateCrowds = this.updateCrowds.bind(this);
    }

    componentDidMount() {
        this.updateCrowds();
    }

    updateCrowds() {
        this.setState({isLoading: true, itemsSelected: []});
        getCrowds(true)
            .then(crowds => this.setState({crowds, isLoading: false}))
            .catch(error => console.log(error));
    }

    handleDelete() {
        const selectedItem = this.state.crowds.find(x => x.id === parseInt(this.state.itemsSelected[0]))
        this.setState({
            confirmDeleteDialogOpen: true,
            deleteItemDescription: selectedItem.name,
        });
    }

    handleConfirmDelete() {
        this.setState({
            confirmDeleteDialogOpen: false,
            deleteItemDescription: '',
        });
        deleteCrowd(this.state.itemsSelected[0])
            .then(() => {
                this.updateCrowds();
            })
            .catch((error) => {
                console.log(error);
            });
    }

    handleDismissDelete() {
        this.setState({
            itemsSelected: [],
            confirmDeleteDialogOpen: false,
            deleteItemDescription: '',
        });
    }

    handleSelectionModelChange(newSelection) {
        this.setState({itemsSelected: newSelection.selectionModel});
    }

    handleCreate() {
        this.props.history.push(ROUTE_ADMIN_CROWDS_EDIT);
    }

    handleEdit() {
        this.props.history.push(ROUTE_ADMIN_CROWDS_EDIT + "/" + this.state.itemsSelected[0]);
    }

    render() {
        const {classes} = this.props;
        const {crowds, itemsSelected, isLoading, confirmDeleteDialogOpen, deleteItemDescription} = this.state;

        return (
            <Grid container spacing={4} className={classes.root}>
                <Grid item xs={12} className={classes.tableContainer}>
                    <Paper className={classes.paperTable}>
                        <DataGrid columns={columns}
                                  rows={crowds}
                                  loading={isLoading}
                                  selectionModel={itemsSelected}
                                  onSelectionModelChange={this.handleSelectionModelChange}
                                  pageSize={10}
                                  sortModel={[{field: 'name', sort: 'asc'}]}
                                  disableColumnMenu
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12} container justify="space-between">
                    <Grid item>
                        <Button color="primary"
                                startIcon={<Edit/>}
                                variant="contained"
                                onClick={this.handleEdit}
                                disabled={!itemsSelected || itemsSelected.length < 1}
                        >
                            Bearbeiten
                        </Button>
                        <Button color="primary"
                                startIcon={<Delete/>}
                                variant="outlined"
                                onClick={this.handleDelete}
                                className={classes.buttonDelete}
                                disabled={!itemsSelected || itemsSelected.length < 1}>
                            Löschen
                        </Button>
                        <ConfirmDeleteDialog
                            open={confirmDeleteDialogOpen}
                            onConfirm={this.handleConfirmDelete}
                            onDismiss={this.handleDismissDelete}
                            itemDescription={deleteItemDescription}
                        />
                    </Grid>
                    <Grid item>
                        <Button variant="contained"
                                color="primary"
                                startIcon={<Add/>}
                                onClick={this.handleCreate}
                        >
                            Neue Crowd erstellen
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(AdminCrowds);
