import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {ButtonBase, Grid, Typography} from "@material-ui/core";

import {CROWDSOURCING_STAGES} from "helpers/Constants";

import imgArrow from "media/StageChoicer/arrow.png";
import imgArrowDisabled from "media/StageChoicer/arrowDisabled.png";

const styles = theme => ({
    root: {
        marginBottom: '1.75rem',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'left'
        }
    },
    buttonContainer: {
        textAlign: 'center',
        flexBasis: 'auto',
        marginTop: '1rem',
        [theme.breakpoints.down('xs')]: {
            width: '40%',
            maxWidth: '40%',
        },
        [theme.breakpoints.only('sm')]: {
            width: '26.666666%',
            maxWidth: '26.666666%',
        },
        [theme.breakpoints.up('md')]: {
            width: '13.333333%',
            maxWidth: '13.333333%',
        },
    },
    arrowContainer: {
        flexBasis: 'auto',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        marginTop: '1rem',
        marginBottom: '1.295rem',
        [theme.breakpoints.down('xs')]: {
            width: '10%',
            maxWidth: '10%',
        },
        [theme.breakpoints.only('sm')]: {
            width: '6.666666%',
            maxWidth: '6.666666%',
        },
        [theme.breakpoints.up('md')]: {
            width: '4%',
            maxWidth: '4%',
        },
    },
    buttonImage: {
        width: '100%',
    },
    arrowImage: {
        width: '67%',
    },
    buttonText: {
        display: 'block',
        marginTop: theme.spacing(1),
        fontSize: '0.74rem',
    },
    buttonBase: {
        width: '80%',
        borderRadius: '50%',
    },
});

class StageChoicer extends React.Component {
    static defaultProps = {
        stage: -1
    }

    static propTypes = {
        classes: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);
        this.state = {};
        this.buttonHandler = this.buttonHandler.bind(this)
    }

    buttonHandler(stage) {
        const newStage = this.props.stage === stage ? -1 : stage;
        if (this.props.onChange) this.props.onChange(newStage);
    }

    render() {
        const { classes, stage } = this.props;

        const buttonBaseProps = {focusRipple: true, className: classes.buttonBase};

        const createButtonHandler = (stage) => () => this.buttonHandler(stage);

        return (
            <Grid container justify="space-evenly" className={classes.root}>
                <Grid item className={classes.buttonContainer}>
                    <ButtonBase {...buttonBaseProps} onClick={createButtonHandler(CROWDSOURCING_STAGES[0].id)}>
                        <img src={(stage < 0 || stage === CROWDSOURCING_STAGES[0].id) ?
                            CROWDSOURCING_STAGES[0].icon : CROWDSOURCING_STAGES[0].iconDisabled}
                             alt={CROWDSOURCING_STAGES[0].description}
                             className={classes.buttonImage}
                        />
                    </ButtonBase>
                    <Typography variant='button' className={classes.buttonText}>
                        {CROWDSOURCING_STAGES[0].description}
                    </Typography>
                </Grid>
                <Grid item className={classes.arrowContainer}>
                    <img src={stage < 0 ? imgArrow : imgArrowDisabled} className={classes.arrowImage}
                         alt="Pfeil nach rechts"/>
                </Grid>
                <Grid item className={classes.buttonContainer}>
                    <ButtonBase {...buttonBaseProps} onClick={createButtonHandler(CROWDSOURCING_STAGES[1].id)}>
                        <img src={(stage < 0 || stage === CROWDSOURCING_STAGES[1].id) ?
                            CROWDSOURCING_STAGES[1].icon : CROWDSOURCING_STAGES[1].iconDisabled}
                             alt={CROWDSOURCING_STAGES[1].description} className={classes.buttonImage}
                        />
                    </ButtonBase>
                    <Typography variant='button' className={classes.buttonText}>
                        {CROWDSOURCING_STAGES[1].description}
                    </Typography>
                </Grid>
                <Grid item className={classes.arrowContainer}>
                    <img src={stage < 0 ? imgArrow : imgArrowDisabled} className={classes.arrowImage}
                         alt="Pfeil nach rechts"/>
                </Grid>
                <Grid item className={classes.buttonContainer}>
                    <ButtonBase {...buttonBaseProps} onClick={createButtonHandler(CROWDSOURCING_STAGES[2].id)}>
                        <img src={(stage < 0 || stage === CROWDSOURCING_STAGES[2].id) ?
                            CROWDSOURCING_STAGES[2].icon : CROWDSOURCING_STAGES[2].iconDisabled}
                             alt={CROWDSOURCING_STAGES[2].description} className={classes.buttonImage}
                        />
                    </ButtonBase>
                    <Typography variant='button' className={classes.buttonText}>
                        {CROWDSOURCING_STAGES[2].description}
                    </Typography>
                </Grid>
                <Grid item className={classes.arrowContainer}>
                    <img src={stage < 0 ? imgArrow : imgArrowDisabled} className={classes.arrowImage}
                         alt="Pfeil nach rechts"/>
                </Grid>
                <Grid item className={classes.buttonContainer}>
                    <ButtonBase {...buttonBaseProps} onClick={createButtonHandler(CROWDSOURCING_STAGES[3].id)}>
                        <img src={(stage < 0 || stage === CROWDSOURCING_STAGES[3].id) ?
                            CROWDSOURCING_STAGES[3].icon : CROWDSOURCING_STAGES[3].iconDisabled}
                             alt={CROWDSOURCING_STAGES[3].description} className={classes.buttonImage}
                        />
                    </ButtonBase>
                    <Typography variant='button' className={classes.buttonText}>
                        {CROWDSOURCING_STAGES[3].description}
                    </Typography>
                </Grid>
                <Grid item className={classes.arrowContainer}>
                    <img src={stage < 0 ? imgArrow : imgArrowDisabled} className={classes.arrowImage}
                         alt="Pfeil nach rechts"/>
                </Grid>
                <Grid item className={classes.buttonContainer}>
                    <ButtonBase {...buttonBaseProps} onClick={createButtonHandler(CROWDSOURCING_STAGES[4].id)}>
                        <img src={(stage < 0 || stage === CROWDSOURCING_STAGES[4].id) ?
                            CROWDSOURCING_STAGES[4].icon : CROWDSOURCING_STAGES[4].iconDisabled}
                             alt={CROWDSOURCING_STAGES[4].description} className={classes.buttonImage}
                        />
                    </ButtonBase>
                    <Typography variant='button' className={classes.buttonText}>
                        {CROWDSOURCING_STAGES[4].description}
                    </Typography>
                </Grid>
                <Grid item className={classes.arrowContainer}>
                    <img src={stage < 0 ? imgArrow : imgArrowDisabled} className={classes.arrowImage}
                         alt="Pfeil nach rechts"/>
                </Grid>
                <Grid item className={classes.buttonContainer}>
                    <ButtonBase {...buttonBaseProps} onClick={createButtonHandler(CROWDSOURCING_STAGES[5].id)}>
                        <img src={(stage < 0 || stage === CROWDSOURCING_STAGES[5].id) ?
                                  CROWDSOURCING_STAGES[5].icon : CROWDSOURCING_STAGES[5].iconDisabled}
                             alt={CROWDSOURCING_STAGES[5].description}
                             className={classes.buttonImage}
                        />
                    </ButtonBase>
                    <Typography variant='button' className={classes.buttonText}>
                        {CROWDSOURCING_STAGES[5].description}
                    </Typography>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(StageChoicer);
