import React from 'react';
import PropTypes from "prop-types";
import {withStyles} from '@material-ui/core/styles';
import axios from "axios";
import {
    Button,
    Container,
    Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    MenuItem,
    TextField
} from "@material-ui/core";
import ReCAPTCHA from "react-google-recaptcha";
import clsx from "clsx";
import {getCrowds} from "helpers/api";
import validator from "validator";
import FullscreenProgress from "components/FullscreenProgress";
import NetworkErrorDialog from "components/NetworkErrorDialog";

const styles = theme => ({
    root: {},
    centerText: {
        textAlign: 'center',
    },
    captchaContainer: {
        display: 'inline-block',
    },
});

class ContactForm extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        variant: PropTypes.string,
    }

    static defaultProps = {
        variant: 'contact'
    }

    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            message: "",
            crowd: "",
            crowdName: "",
            crowdWebsite: "",
            crowdPricing: "",
            crowdAudience: "",
            nameErrorMessage: null,
            emailErrorMessage: null,
            messageErrorMessage: null,
            crowdNameErrorMessage: null,
            crowdWebsiteErrorMessage: null,
            crowdPricingErrorMessage: null,
            crowdAudienceErrorMessage: null,
            crowdErrorMessage: null,
            data: null,
            captcha: null,
            isLoading: true,
            networkError: false,
            showErrorDialog: false,
            showSuccessDialog: false,
        }
        this.recaptcha = React.createRef();
        this.handleSubmit = this.handleSubmit.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.onCaptchaChange = this.onCaptchaChange.bind(this);
        this.handleAbortSending = this.handleAbortSending.bind(this);
        this.handleRetrySending = this.handleRetrySending.bind(this);
        this.handleConfirmSuccess = this.handleConfirmSuccess.bind(this);
    }

    handleSubmit() {
        const {name, email, captcha, message, crowdName, crowdWebsite, crowdPricing, crowdAudience, crowd} = this.state;
        const {variant} = this.props;

        let valid = true;
        valid &= this.validate('captcha', captcha);
        valid &= this.validate('name', name);
        valid &= this.validate('email', email);
        if (variant === 'contact') {
            valid &= this.validate('message', message);
        } else if (variant === 'newcrowd') {
            valid &= this.validate('crowdName', crowdName);
            valid &= this.validate('crowdWebsite', crowdWebsite);
            valid &= this.validate('crowdPricing', crowdPricing);
            valid &= this.validate('crowdAudience', crowdAudience);
        } else if (variant === 'changecrowd') {
            valid &= this.validate('message', message);
            valid &= this.validate('crowd', crowd);
        } else {
            return;
        }
        if(!valid) return;

        let sendInformation;
        if (variant === "contact") {
            sendInformation = {
                'feedbackOption': variant,
                name,
                email,
                message,
                captcha
            }
        } else if (variant === "newcrowd") {
            sendInformation = {
                'feedbackOption': variant,
                name,
                email,
                crowdName,
                crowdWebsite,
                crowdPricing,
                crowdAudience,
                captcha
            }
        } else if (variant === "changecrowd") {
            sendInformation = {
                'feedbackOption': variant,
                name,
                email,
                message,
                crowd,
                captcha
            }
        } else {
            return;
        }

        this.setState({isLoading: true,})
        axios.post(process.env.REACT_APP_API_URL + '/send', sendInformation)
            .then((response) => {
                this.setState({
                    isLoading: false,
                    showSuccessDialog: true,
                })
            })
            .catch((error) => {
                this.setState({
                    isLoading: false,
                    showErrorDialog: true,
                })
            });
    }

    resetForm() {
        this.setState({
            name: "",
            email: "",
            message: "",
            crowdName: "",
            crowdWebsite: "",
            crowdPricing: "",
            crowdAudience: "",
            crowd: "",
            nameErrorMessage: null,
            emailErrorMessage: null,
            messageErrorMessage: null,
            crowdNameErrorMessage: null,
            crowdWebsiteErrorMessage: null,
            crowdPricingErrorMessage: null,
            crowdAudienceErrorMessage: null,
            crowdErrorMessage: null,
            captcha: null
        })
        if (this.recaptcha.current !== null) this.recaptcha.current.reset();
    }

    onCaptchaChange(value) {
        this.setState({
            captcha: value
        });
        if(!value && this.state.showErrorDialog) {
            this.setState({
                showErrorDialog: false,
            });
        }
    }

    componentDidMount() {
        getCrowds()
            .then((response) => {
                this.setState({
                    isLoading: false,
                    data: response
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    isLoading: false,
                    networkError: true,
                });
            });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.variant !== prevProps.variant) {
            this.resetForm();
        }
    }

    validate(name, value) {
        let errorMessage = null;
        const messageMandatory = 'Dies ist ein Pflichtfeld.'

        switch (name) {
            case 'captcha':
                if(!value) errorMessage = 'Bitte Kontrollfrage beantworten.';
                break;
            case 'crowdWebsite':
                if(!validator.isLength(value, {min: 1})) {
                    errorMessage = messageMandatory;
                } else if(!validator.isURL(value)) {
                    errorMessage = 'Bitte geben Sie eine zulässige Internetadresse an.'
                }
                break;
            case 'crowd':
                if(!validator.isLength(value, {min: 1}))
                    errorMessage = messageMandatory;
                break;
            case 'email':
                if(!validator.isLength(value, {min: 1})) {
                    errorMessage = messageMandatory;
                } else if(!validator.isEmail(value)) {
                    errorMessage = 'Bitte geben Sie eine zulässige E-Mail-Adresse an.'
                }
                break;
            default:
                if(!validator.isLength(value, {min: 1}))
                    errorMessage = messageMandatory;
                else if(!validator.isLength(value, {min: 3}))
                    errorMessage = 'Bitte geben Sie mindestens 3 Zeichen ein.';
        }
        this.setState({[name+'ErrorMessage']: errorMessage});
        return errorMessage === null;
    }

    handleChange(event, field) {
        let newValue = event.target.value;

        this.setState({
            [field]: newValue,
            [field + 'ErrorMessage']: null
        });
    }

    handleBlur(event, field) {
        this.validate(field, this.state[field]);
    }

    handleAbortSending() {
        this.setState({showErrorDialog: false,});
    }

    handleRetrySending() {
        this.setState({showErrorDialog: false,});
        this.handleSubmit();
    }

    handleConfirmSuccess() {
        this.setState({showSuccessDialog: false,});
        this.resetForm();
    }

    render() {
        const {style, classes, className, variant} = this.props;

        const createChangeHandler = (field) => (event) => this.handleChange(event, field);
        const createBlurHandler = (field) => (event) => this.handleBlur(event, field);

        const {
            captcha,
            data,
            isLoading,
            networkError,
            name,
            nameErrorMessage,
            email,
            emailErrorMessage,
            message,
            messageErrorMessage,
            crowd,
            crowdName,
            crowdNameErrorMessage,
            crowdWebsite,
            crowdWebsiteErrorMessage,
            crowdPricing,
            crowdPricingErrorMessage,
            crowdAudience,
            crowdAudienceErrorMessage,
            crowdErrorMessage,
            showErrorDialog,
            showSuccessDialog,
        } = this.state;

        return (
            <Container maxWidth="sm" style={style} className={clsx(classes.root, className)}>
                <FullscreenProgress open={isLoading}/>
                <NetworkErrorDialog open={networkError}/>

                <form id="contact-form" onSubmit={this.handleSubmit} method="POST">
                    {(variant === "changecrowd" || variant === "newcrowd" || variant === "contact") &&
                    <Grid container spacing={3} justify="center">
                        <Grid item xs={12}>
                            <TextField id="name" label="Ihr Name" placeholder="Vor- und Nachname" fullWidth
                                       variant="outlined" value={name}
                                       onChange={createChangeHandler('name')}
                                       onBlur={createBlurHandler('name')}
                                       required
                                       error={!!nameErrorMessage} helperText={nameErrorMessage}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField id="email" label="Ihre E-Mail" placeholder="name@domain.com" fullWidth
                                       variant="outlined" value={email}
                                       onChange={createChangeHandler('email')}
                                       onBlur={createBlurHandler('email')}
                                       required
                                       error={!!emailErrorMessage} helperText={emailErrorMessage}
                            />
                        </Grid>
                        {variant === "newcrowd" &&
                        <Grid item xs={12}>
                            <TextField id="crowd-name" label="Name der Crowd" placeholder="Anbietername" fullWidth
                                       variant="outlined" value={crowdName}
                                       onChange={createChangeHandler('crowdName')}
                                       onBlur={createBlurHandler('crowdName')}
                                       required
                                       error={!!crowdNameErrorMessage} helperText={crowdNameErrorMessage}/>
                        </Grid>
                        }
                        {variant === "newcrowd" &&
                        <Grid item xs={12}>
                            <TextField id="crowd-website" label="Website der Crowd" placeholder="www.anbieterdomain.de"
                                       fullWidth variant="outlined" value={crowdWebsite}
                                       onChange={createChangeHandler('crowdWebsite')}
                                       onBlur={createBlurHandler('crowdWebsite')}
                                       required
                                       error={!!crowdWebsiteErrorMessage} helperText={crowdWebsiteErrorMessage}/>
                        </Grid>
                        }
                        {variant === "newcrowd" &&
                        <Grid item xs={12}>
                            <TextField id="crowd-pricing" label="Link/Quelle zur Preisgestaltung"
                                       placeholder="Link oder Quellenangabe" fullWidth variant="outlined"
                                       value={crowdPricing}
                                       onChange={createChangeHandler('crowdPricing')}
                                       onBlur={createBlurHandler('crowdPricing')}
                                       required
                                       error={!!crowdPricingErrorMessage} helperText={crowdPricingErrorMessage}/>
                        </Grid>
                        }
                        {variant === "newcrowd" &&
                        <Grid item xs={12}>
                            <TextField id="crowd-audience" label="Link/Quelle zur Zielgruppe (Community-Typen)"
                                       placeholder="Link oder Quellenangabe" fullWidth variant="outlined"
                                       value={crowdAudience}
                                       onChange={createChangeHandler('crowdAudience')}
                                       onBlur={createBlurHandler('crowdAudience')}
                                       required
                                       error={!!crowdAudienceErrorMessage} helperText={crowdAudienceErrorMessage}/>
                        </Grid>
                        }
                        {variant === "changecrowd" &&
                        <Grid item xs={12}>
                            <TextField id="crowd" label="Name der Crowd" select fullWidth variant="outlined"
                                       value={!!data ? crowd : ''}
                                       onChange={createChangeHandler('crowd')}
                                       onBlur={createBlurHandler('crowd')}
                                       required
                                       error={!!crowdErrorMessage} helperText={crowdErrorMessage} disabled={!!data}>
                                {!!data && data.map(item =>
                                    <MenuItem key={item.id} value={item.name}>
                                        {item.name}
                                    </MenuItem>
                                )}
                            </TextField>
                        </Grid>
                        }
                        {(variant === "changecrowd" || variant === "contact") &&
                        <Grid item xs={12}>
                            <TextField id="message" label="Ihre Nachricht" placeholder="Ihre Nachricht an uns"
                                       multiline rows={5} fullWidth variant="outlined" value={message}
                                       onChange={createChangeHandler('message')}
                                       onBlur={createBlurHandler('message')}
                                       required
                                       error={!!messageErrorMessage} helperText={messageErrorMessage}/>
                        </Grid>
                        }
                        <Grid item xs={12} className={classes.centerText}>
                            <div className={classes.captchaContainer}>
                                <ReCAPTCHA
                                    ref={this.recaptcha}
                                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                    onChange={this.onCaptchaChange}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} className={classes.centerText}>
                            <Button variant="contained" color="primary" onClick={this.handleSubmit}
                                    disabled={!captcha}>
                                Anfrage senden
                            </Button>
                        </Grid>
                    </Grid>
                    }
                </form>
                <Dialog open={showErrorDialog} onClose={this.handleAbortSending}>
                    <DialogTitle>Es ist ein Fehler aufgetreten.</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Ihre Nachricht konnte nicht gesendet werden.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleAbortSending} color="primary">
                            Abbrechen
                        </Button>
                        <Button onClick={this.handleRetrySending} color="primary" autoFocus>
                            Wiederholen
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={showSuccessDialog} onClose={this.handleConfirmSuccess}>
                    <DialogTitle>Vielen Dank für Ihre Nachricht.</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Ihre Nachricht wurde erfolgreich gesendet.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleConfirmSuccess} color="primary" autoFocus>
                            Weiter
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        );
    }
}

export default withStyles(styles)(ContactForm);
