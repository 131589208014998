import React from 'react';
import PropTypes from "prop-types";
import {withStyles} from '@material-ui/core/styles';
import {Typography} from "@material-ui/core";

import SectionHeading from "components/SectionHeading";
import ContactForm from "components/ContactForm";

const styles = theme => ({
    root: {},
    contactForm: {
        marginTop: '2rem',
    }
});

class CrowdAnbieter extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired
    }

    render() {
        const {classes} = this.props;

        return (
            <React.Fragment>
                <SectionHeading>Bieten Sie Ihre Crowd im Crowd-Finder an</SectionHeading>

                <Typography align="justify" paragraph>
                    Gerade kleine und mittlere Unternehmen sind mit dem Thema Crowdsourcing noch nicht sonderlich
                    vertraut. Zu viele Barrieren und Informationslücken erschweren die Einbindung einer Crowd in
                    den Produktentwicklungsprozess. Da gerade für kleine und mittlere Unternehmen häufig interne
                    Ressourcen für verschiedene Stufen im Produktentwicklungsprozess fehlen und dementsprechend
                    Crowdsourcing positiv auf den Produkterfolg einwirken kann, wurde der Crowd-Finder entwickelt.
                </Typography>

                <Typography align="justify" paragraph>
                    Durch unseren Crowd-Finder erhalten KMU die Möglichkeit die Vielzahl existierender
                    Crowdsourcing-Plattformen direkt ihren individuellen unternehmens- und kontextspezifischen
                    Herausforderungen zuzuordnen. Dies ermöglicht insbesondere bei Aufgaben, die nicht den
                    Kernkompetenzen des Unternehmens entsprechen, den Rückgriff auf externes Wissen der
                    Crowd und somit eine Steigerung der Effizienz von KMU.
                </Typography>

                <Typography align="justify" paragraph>
                    Helfen auch Sie den kleineren und mittleren Unternehmen Ihre Crowdsourcingplattform im
                    Crowd-Finder zu finden. Durch die Listung Ihrer Plattform erhöhen Sie die Wahrscheinlichkeit
                    von unserer Zielgruppe gefunden und kontaktiert zu werden. Des Weiteren leisten Sie dadurch
                    einen weitere Beitrag das Thema Crowdsourcing der breiten Masse zugänglich zu machen.
                </Typography>

                <Typography align="justify" paragraph>
                    <b>Werden Sie jetzt Teil des Crowd-Finders.</b>
                </Typography>

                <Typography align="justify" paragraph>
                    Füllen Sie einfach unser Kontaktformular aus und wir werden uns mit Ihnen in Verbindung setzen.
                    Wir freuen uns auf Ihre Anfrage.
                </Typography>

                <SectionHeading>Kontaktformular</SectionHeading>

                <ContactForm variant="newcrowd" className={classes.contactForm}/>
            </React.Fragment>
        );
    }

}

export default withStyles(styles)(CrowdAnbieter);
